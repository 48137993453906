import explain from "./explain";
import {STYLE} from "./style";
import hljs from "highlight.js/lib/common";

import katex from "katex"
import 'katex/dist/contrib/mhchem.js' // modify katex module

const ELEMENT_TYPE = {
    H1: "h1",
    H2: "h2",
    H3: "h3",
    H4: "h4",
    H5: "h5",
    H6: "h6",
    P: "p",
    SPAN: "span",
    DIV: "div",
    PRE: "pre",
    CODE: "code",
    UL: "ul",
    LI: "li",
    BLOCKQUOTE: "blockquote",
    TABLE: "table",
    THEAD: "thead",
    TR: "tr",
    TH: "th",
    TD: "td",
    TBODY: "tbody",
    SECTION: "section",
    EM: "em"
}
export default {
    createEle(name) {
        return document.createElement(name)
    },
    createH1(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H1)
        ele.style = STYLE.H1
        var innerHTML = explain.dealLine(item.substring(2))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    },
    createH2(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H2)
        ele.style = STYLE.H2
        var innerHTML = explain.dealLine(item.substring(3))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    },
    createH3(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H3)
        ele.style = STYLE.H3
        var innerHTML = explain.dealLine(item.substring(4))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    },
    createH4(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H4)
        ele.style = STYLE.H4
        var innerHTML = explain.dealLine(item.substring(5))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)

    },
    createH5(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H5)
        ele.style = STYLE.H5
        var innerHTML = explain.dealLine(item.substring(6))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)

    },
    createH6(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H6)
        ele.style = STYLE.H6

        var innerHTML = explain.dealLine(item.substring(7))
        ele.innerHTML = innerHTML

        this.addToSection(sections, ele)

    },

    createCenterH1(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H1)
        ele.style = STYLE.CENTER_H1
        var innerHTML = explain.dealLine(item.substring(3))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    },
    createCenterH2(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H2)
        ele.style = STYLE.CENTER_H2
        var innerHTML = explain.dealLine(item.substring(4))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    },
    createCenterH3(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H3)
        ele.style = STYLE.CENTER_H3
        var innerHTML = explain.dealLine(item.substring(5))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    },
    createCenterH4(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H4)
        ele.style = STYLE.CENTER_H4
        var innerHTML = explain.dealLine(item.substring(6))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)

    },
    createCenterH5(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H5)
        ele.style = STYLE.CENTER_H5
        var innerHTML = explain.dealLine(item.substring(7))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)

    },
    createCenterH6(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.H6)
        ele.style = STYLE.CENTER_H6

        var innerHTML = explain.dealLine(item.substring(8))
        ele.innerHTML = innerHTML

        this.addToSection(sections, ele)

    },

    createQuote1(item, sections) {
        var str = explain.dealLine(item)
        var arr = str.split("\n")
        var index = 0
        var ele = document.createElement("blockquote")
        ele.style = STYLE.BLOCKQUOTE1
        var arrRes = []
        get()

        var pos = 0
        var preGrade1 = 1
        createRes()

        function createRes(line = arrRes[pos], pre = null) {
            if (pos < arrRes.length) {
                var currentGrade = getGrade(line)
                if (currentGrade < preGrade1) {
                    if (pre != null) {
                        ele.appendChild(pre)
                    }
                    //    >>
                    //    >>>

                    preGrade1 = currentGrade
                    pos++
                    createRes(arrRes[pos], create(line, currentGrade, currentGrade))
                } else {
                    var temp = create(line, currentGrade - preGrade1 + 1, currentGrade)
                    if (pre != null) {
                        pre.appendChild(temp)
                        ele.appendChild(pre)
                    }
                    preGrade1 = currentGrade
                    pos++
                    createRes(arrRes[pos], temp)
                    // ele.appendChild(pre)

                }
            } else {
                if (pre != null) {
                    ele.append(pre)
                }
            }
        }

        function getGrade(item) {
            return item.match(/\>*/)[0].length
        }

        var preGrade = 1

        function get(line = arr[0], temp = "") {
            if (index < arr.length) {
                var currentGrade = getGrade(line)
                if (currentGrade != preGrade) {
                    if (temp != "") {
                        arrRes.push(temp)
                    }
                    temp = line
                } else if (currentGrade == preGrade) {
                    temp += (temp == "" ? line + "\n" : "\n" + line.substring(currentGrade))
                }
                preGrade = currentGrade
                index++
                get(arr[index], temp)
            } else {
                if (temp != "") {
                    arrRes.push(temp)
                }
            }
        }

        // function get(line = arr[0], preBq, temp = "") {
        //     if (index < arr.length) {
        //         var currentGrade = getGrade(line)
        //         console.log(currentGrade)
        //         if (currentGrade < preGrade) {
        //             if (temp != "") {
        //                 ele.append(create(temp, preGrade))
        //             }
        //             if (preBq != null) {
        //                 ele.append(preBq.cloneNode(true))
        //                 preBq = null
        //             }
        //             temp = line
        //         } else if (currentGrade > preGrade) {
        //             if (preBq != null) {
        //                 if (temp != "") {
        //                     preBq.append(create(temp, currentGrade-preGrade))
        //                     temp = line
        //                 } else {
        //                     preBq.append(create(line, currentGrade-preGrade))
        //                     temp = ""
        //                 }
        //             } else {
        //                 if (temp != "") {
        //                     preBq = create(temp, currentGrade-preGrade)
        //                     temp = line
        //                 } else {
        //                     preBq = create(line, currentGrade-preGrade)
        //                     temp = ""
        //                 }
        //             }
        //         } else if (currentGrade == preGrade) {
        //             temp += (temp == "" ? line + "\n" : "\n" + line.substring(currentGrade))
        //             preBq = create(temp, currentGrade)
        //         }
        //         preGrade = currentGrade
        //         index++
        //         get(arr[index], preBq, temp)
        //     } else {
        //         if (temp != "") {
        //             ele.append(create(temp, preGrade))
        //         }
        //         if (preBq != null) {
        //             ele.append(preBq)
        //         }
        //     }
        // }

        function create(item, grade = 1, len = 1) {
            if (grade == 1) {
                var p = document.createElement("p")
                // p.innerHTML =item.substring(grade)
                var innerHTMLArr = item.substring(len).split("\n")
                innerHTMLArr.forEach(value => {
                    var pp = document.createElement("p")
                    pp.innerHTML = value
                    p.append(pp)
                })
                return p
            } else {
                var blockquote = document.createElement("blockquote")
                var sub = create(item.substring(1), grade - 1, len - 1)
                blockquote.append(sub)
                blockquote.style = STYLE.BLOCKQUOTE1
                return blockquote
            }
        }

        this.addToSection(sections, ele)

        return
        var blockquote = this.createEle(ELEMENT_TYPE.BLOCKQUOTE)
        var p = this.createEle(ELEMENT_TYPE.P)
        p.style = STYLE.BLOCKQUOTE_P
        var that = this
        var is2 = false

        function dealQuoteLine(line, blockquote) {
            if (explain.isQuote2(line)) {
                line = line.substring(1)
                var blockquote2 = that.createEle(ELEMENT_TYPE.BLOCKQUOTE)
                blockquote2.style = STYLE.BLOCKQUOTE1
                dealQuoteLine(line, blockquote2)
                blockquote.append(blockquote2)
            } else {
                var innerHTML = line.substring(1)
                var isH = explain.isH(innerHTML)
                if (isH[0]) {
                    that.createH(isH[1], innerHTML, [blockquote])
                } else {
                    p.innerHTML = innerHTML
                    blockquote.append(p.cloneNode(true))
                }
            }
        }

        arr.forEach(line => {
            dealQuoteLine(line, blockquote)
        })
        // if (is2) {
        //     blockquote.append(blockquote2.cloneNode(true))
        //     is2 = false
        //     blockquote2 = null
        // }
        blockquote.style = STYLE.BLOCKQUOTE1
        var section = this.createEle(ELEMENT_TYPE.SECTION)
        this.addToSection(sections, blockquote)

    }

    ,
    createQuote2(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.BLOCKQUOTE)
        ele.style = STYLE.BLOCKQUOTE2
        var innerHTML = explain.dealLine(item.substring(3))
        ele.innerHTML = innerHTML
        this.addToSection(sections, ele)
    }
    ,

    createList(item, sections) {

        var list = item.split("\n")
        var ul1, li1
        var ul2, li2
        var ul3, li3
        var that = this

        function appendUl3() {
            if (ul3 != null) {
                if (li2 == null) {
                    li2 = that.createEle(ELEMENT_TYPE.LI)
                    li2.style = STYLE.LI
                }
                li2.append(ul3)
                if (ul2 == null) {
                    ul2 = that.createEle(ELEMENT_TYPE.UL)
                    ul2.style = STYLE.UL
                    ul2.append(li2)
                }
                if (li1 == null) {
                    li1 = that.createEle(ELEMENT_TYPE.LI)
                    li1.style = STYLE.LI
                }
                if (ul1 == null) {
                    ul1 = that.createEle(ELEMENT_TYPE.UL)
                    ul1.style = STYLE.UL
                    ul1.append(li1)
                }
                li1.append(ul2)
            }
            ul3 = null
            li3 = null
        }

        function appendUl2() {
            if (ul2 != null) {
                if (li1 == null) {
                    li1 = that.createEle(ELEMENT_TYPE.LI)
                    li1.style = STYLE.LI
                }
                if (ul1 == null) {
                    ul1 = that.createEle(ELEMENT_TYPE.UL)
                    ul1.style = STYLE.UL
                    ul2.append(li2)
                }
                li1.append(ul2)
            }
            ul2 = null
            li2 = null
        }


        var ele
        list.forEach(item => {
                if (explain.isList1(item)) {
                    appendUl3();
                    appendUl2();
                    li1 = that.createEle(ELEMENT_TYPE.LI)
                    var innerHTML = explain.dealLine(item.substring(2))
                    li1.innerHTML = innerHTML
                    li1.style = STYLE.LI
                    if (ul1 == null) {
                        ul1 = that.createEle(ELEMENT_TYPE.UL)
                        ul1.style = STYLE.UL
                    }
                    ul1.append(li1)
                } else if (explain.isList2(item)) {
                    appendUl3()
                    appendUl2();
                    var innerHTML = explain.dealLine(item.substring(4))
                    li2 = that.createEle(ELEMENT_TYPE.LI)
                    li2.innerHTML = innerHTML
                    li2.style = STYLE.LI
                    if (ul2 == null) {
                        ul2 = that.createEle(ELEMENT_TYPE.UL)
                        ul2.style = STYLE.UL
                    }
                    ul2.append(li2)
                } else if (explain.isList3(item)) {
                    var innerHTML = explain.dealLine(item.substring(6))
                    li3 = that.createEle(ELEMENT_TYPE.LI)
                    li3.innerHTML = innerHTML
                    li3.style = STYLE.LI
                    if (ul3 == null) {
                        ul3 = that.createEle(ELEMENT_TYPE.UL)
                        ul3.style = STYLE.UL
                    }
                    ul3.append(li3)
                }
            }
        )
        appendUl3()
        appendUl2()
        ele = ul1

        this.addToSection(sections, ele)

    }
    ,

    createCode(item, sections, hljs) {
        var innerHTML = item.substring(4)
        var p = this.createEle(ELEMENT_TYPE.P)
        p.style.padding=""
        p.style.margin=""
        var code = this.createEle(ELEMENT_TYPE.CODE)

        //此种方法在微信编辑器里面不适用，包括flex布局、relative布局均失败
        // const linesLength = innerHTML.split(/\n/).length - 1;
        // // 生成行号
        // let linesNum = '<span aria-hidden="true" style="' +
        //     'font-family: Consolas,Menlo,Courier !important; ' +
        //     'pointer-events: none;' +
        //     'top: 12px;line-height: 24px !important;' +
        //     'color:#7c868fba;left: 0;font-size: 100%;width: 45px;text-align: center;letter-spacing: -1px;border-right: 1px solid rgba(0, 0, 0, .32);-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;counter-reset: linenumber;">';
        // for (let index = 0; index < linesLength+1; index++) {
        //     linesNum = linesNum + "<span style='pointer-events: none;display: block;counter-increment: linenumber;'>"+(index+1)+"</span>";
        // }
        // linesNum += "</span>";
        // // highlight.js 高亮代码
        // var preCode = hljs.highlightAuto(innerHTML).value
        // var html = `
        //         <pre class="hljs" style="padding: 12px 1px 0px 5px !important;
        //         border-radius: 5px!important;
        //         font-size: 14px!important;
        //         margin:0;
        //         overflow: hidden!important;display:flex;">
        //         ${linesNum}
        //         <code style="font-family: Consolas,Menlo,Courier !important;line-height: 24px !important;margin-left: 5px;">${preCode}</code>
        //         </pre>`

        //采用微信策略
        const lines = innerHTML.split(/\n/);
        var linesLength = lines.length ;
        var html =""
        for (let index = 0; index < linesLength; index++) {
            html+=   "<code style='display: flex;flex-direction: row;align-content: center;color:#7c868fba;'><p style='min-width: 40px;border-right: 1px solid rgba(0, 0, 0, .32);text-align: center !important;margin-right: 6px;font-family:Optima-Regular, Optima, PingFangSC-light, PingFangTC-light, \"PingFang SC\", Cambria, Cochin, Georgia, Times, \"Times New Roman\", serif!important;'>"+(index+1)+"</p><span style='font-family:Optima-Regular, Optima, PingFangSC-light, PingFangTC-light, \"PingFang SC\",Cambria,Cochin,Georgia,Times,\"Times New Roman\", serif !important;'>" + hljs.highlightAuto(lines[index]).value+"</span></code>"
        }
        code.innerHTML =`<code style="font-family:Optima-Regular, Optima, PingFangSC-light, PingFangTC-light, 'PingFang SC', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif!important;line-height: 24px !important;margin-left: 5px;">${html}</code>`
        // code.append(p)
        code.style = STYLE.CODE
        sections.forEach(section => {
            var ele = this.createEle(ELEMENT_TYPE.PRE)
            var span = this.createEle(ELEMENT_TYPE.SPAN)
            span.style = STYLE.CODE_PREFIX
            ele.append(span)
            ele.append(code.cloneNode(true))
            ele.style = STYLE.PRE
            section.append(ele)
        })

    },
    createFormula(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.P)
        var innerHTML = katex.renderToString(item.substring(2))
        ele.innerHTML = innerHTML
        ele.style = STYLE.CENTER_P
        this.addToSection(sections, ele)
    }
    ,

    createTable(item, sections) {
        var list = item.split("\n")
        var tableTitleList = []
        var tableColumnNum = 0

        var tableAlignList = []
        var table, tbody, thead, theadTr, theadTh, tbodyTr, tbodyTd

        var that = this
        list.forEach((line, index) => {
            if (index == 0) {
                tableTitleList = line.split("|").filter((s) => {
                    return s
                });
                tableColumnNum = tableTitleList.length
                if (tableColumnNum < 1) {
                    that.createP(item, sections)
                    return
                }

            } else if (index == 1) {
                tableAlignList = line.split("|").filter((s) => {
                    return s
                });

                if (tableTitleList.length != tableColumnNum) {
                    that.createP(item, sections)
                    return
                }
                var can = true
                tableAlignList = tableAlignList.map(align => {
                    align = align.trim()
                    if (explain.isRepeat("-", align)) {
                        return "text-align:left;"
                    } else if (align.indexOf(":") == 0) {
                        var left = align.substr(1, align.length - 1)
                        if (explain.isRepeat("-", left)) {
                            return "text-align:left;"
                        } else if (left.indexOf(":") == left.length - 1) {
                            left = left.substr(0, left.length - 1)
                            if (explain.isRepeat("-", left)) {
                                return "text-align:center;"
                            }
                        }
                    } else if (align.indexOf(":") == align.length - 1) {
                        var left = align.substr(0, align.length - 1)
                        if (explain.isRepeat("-", left)) {
                            return "text-align:right;"
                        }
                    }
                    can = false
                    return ""
                })
                if (!can) {
                    that.createP(item, sections)
                    return
                }
                table = that.createEle(ELEMENT_TYPE.TABLE)
                thead = that.createEle(ELEMENT_TYPE.THEAD)
                thead.style = STYLE.THEAD
                theadTr = that.createEle(ELEMENT_TYPE.TR)
                theadTr.style = STYLE.TR
                theadTh = that.createEle(ELEMENT_TYPE.TH)
                tableTitleList.forEach((html, pos) => {
                    theadTh.innerHTML = html
                    theadTh.style = STYLE.TH + tableAlignList[pos]
                    theadTr.append(theadTh.cloneNode(true))
                })
                thead.append(theadTr)
                table.append(thead)
            } else {
                if (tbody == null) {
                    tbody = that.createEle(ELEMENT_TYPE.TBODY)
                }
                tbodyTr = that.createEle(ELEMENT_TYPE.TR)
                tbodyTr.style = STYLE.TR
                tbodyTd = that.createEle(ELEMENT_TYPE.TD)
                var rowList = line.split("|").filter((s) => {
                    return s
                })

                if (rowList.length < tableColumnNum) {
                    rowList.forEach((value, pos) => {
                        tbodyTd.innerHTML = value
                        tbodyTd.style = STYLE.TD + tableAlignList[pos]
                        tbodyTr.append(tbodyTd.cloneNode(true))
                    })
                    if (tbodyTr.children.length == rowList.length) {
                        for (var i = 0; i < tableColumnNum - rowList.length; i++) {
                            tbodyTd.innerHTML = ""
                            tbodyTd.style = STYLE.TD + tableAlignList[rowList.length + i]
                            tbodyTr.append(tbodyTd.cloneNode(true))
                        }
                    }
                } else if (rowList.length >= tableColumnNum) {
                    for (var i = 0; i < tableColumnNum; i++) {
                        tbodyTd.innerHTML = rowList[i]
                        tbodyTd.style = STYLE.TD + tableAlignList[i]
                        tbodyTr.append(tbodyTd.cloneNode(true))
                    }
                }
                tbody.append(tbodyTr)
            }
        })
        if (table != null) {
            var section = this.createEle(ELEMENT_TYPE.SECTION)
            // var div1 = this.createEle(ELEMENT_TYPE.DIV)
            table.style = STYLE.TABLE
            if (tbody != null) {
                table.append(tbody)
            }
            section.style = "overflow-x: auto;"
            section.append(table)
            // div1.style = "margin: 20px 0;display:flex;justify-content:center;align-items:center;"
            // div1.append(div)
            this.addToSection(sections, section)
        }
    }
    ,

    createDivider(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.P)
        ele.style = STYLE.DIVIDER
        this.addToSection(sections, ele)

    }
    ,
    createLoop(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.SECTION)
        var innerHTML = explain.dealLoopLine(item.substring(1))
        var sec = this.createEle(ELEMENT_TYPE.SECTION)
        sec.innerHTML = innerHTML
        sec.style = STYLE.LOOP_H.INN
        ele.append(sec)
        var span = this.createEle(ELEMENT_TYPE.SPAN)
        span.innerText = "<<<左右滑动查看更多>>>"
        span.style = "text-align:center;"
        ele.append(span)
        ele.style = STYLE.LOOP_H.OUT+"height:250px;"
        this.addToSection(sections, ele)
    },
    createLoopH(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.SECTION)
        var innerHTML = explain.dealLoopLine(item.substring(2))
        var sec = this.createEle(ELEMENT_TYPE.SECTION)
        sec.innerHTML = innerHTML
        sec.style = STYLE.LOOP_H.INN
        ele.append(sec)
        var span = this.createEle(ELEMENT_TYPE.SPAN)
        span.innerText = "<<<左右滑动查看更多>>>"
        span.style = "text-align:center;"
        ele.append(span)
        ele.style = STYLE.LOOP_H.OUT
        this.addToSection(sections, ele)
    },
    createLoopV(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.SECTION)
        var strArr = item.split(" ")
        var innerHTML = explain.dealLoopLine(item.substring(strArr[0].length))
        var sec = this.createEle(ELEMENT_TYPE.SECTION)
        sec.innerHTML = innerHTML
        sec.style = STYLE.LOOP_V.INN
        ele.append(sec)
        var span = this.createEle(ELEMENT_TYPE.SPAN)
        span.innerText = "<<<上下滑动查看更多>>>"
        span.style = "text-align:center;"
        ele.append(span)
        ele.style = STYLE.LOOP_V.OUT + "height:" + strArr[0].substring(2) + 'px;+width:100%;'
        this.addToSection(sections, ele)
    },
    createRightP(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.P)
        var innerHTML = explain.dealLine(item.substring(2))
        ele.innerHTML = innerHTML
        ele.style = STYLE.RIGHT_P
        this.addToSection(sections, ele)
    }
    ,
    createCenterP(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.P)
        var innerHTML = explain.dealLine(item.substring(2))
        ele.innerHTML = innerHTML
        ele.style = STYLE.CENTER_P
        this.addToSection(sections, ele)
    }
    ,


    createP(item, sections) {
        var ele = this.createEle(ELEMENT_TYPE.P)
        var innerHTML = explain.dealLine(item)
        ele.innerHTML = innerHTML
        ele.style = STYLE.P
        this.addToSection(sections, ele)
    }
    ,
    createFootNote(arr, sections) {
        if (arr.length == 0) {
            return
        }

        this.createH3("### 参考文献", sections)
        var section = this.createEle(ELEMENT_TYPE.SECTION)
        arr.forEach((item, index) => {
            var span = this.createEle(ELEMENT_TYPE.SPAN)
            span.style = STYLE.FOOTNOTE.SPAN
            var spanSub = this.createEle(ELEMENT_TYPE.SPAN)
            spanSub.style = STYLE.FOOTNOTE.SPAN_SUB
            var p = this.createEle(ELEMENT_TYPE.P)
            p.style = STYLE.FOOTNOTE.P+"    margin: 0;"
            var em = this.createEle(ELEMENT_TYPE.EM)
            em.style = STYLE.FOOTNOTE.EM
            var value = item.split(".content.=")[1].split(".link.=")
            spanSub.innerText = "[" + (index + 1) + "]"

            var pSpan = this.createEle(ELEMENT_TYPE.SPAN)
            pSpan.style = STYLE.FOOTNOTE.P_SPAN
            pSpan.innerHTML = value[0]
            p.append(pSpan)
            p.append(":")
            em.innerText = value[1]
            p.append(em)
            span.append(spanSub)
            span.append(p)
            section.append(span)
        })

        section.style = STYLE.FOOTNOTE.SECTION
        this.addToSection(sections, section)
    }
    ,
    createH(type, item, sections) {
        switch (type) {
            case "H1": {
                this.createH1(item, sections)
                break;
            }
            case "H2": {
                this.createH2(item, sections)
                break;
            }
            case "H3": {
                this.createH3(item, sections)
                break;
            }
            case "H4": {
                this.createH4(item, sections)
                break;
            }
            case "H5": {
                this.createH5(item, sections)
                break;
            }
            case "H6": {
                this.createH6(item, sections)
                break;
            }
            case "HC1": {
                this.createCenterH1(item, sections)
                break;
            }
            case "HC2": {
                this.createCenterH2(item, sections)
                break;
            }
            case "HC3": {
                this.createCenterH3(item, sections)
                break;
            }
            case "HC4": {
                this.createCenterH4(item, sections)
                break;
            }
            case "HC5": {
                this.createCenterH5(item, sections)
                break;
            }
            case "HC6": {
                this.createCenterH6(item, sections)
                break;
            }
        }
    }
    ,
    create(item, sections, explain, isEnd) {
        var isH = explain.isH(item)
        if (isH[0]) {
            this.createH(isH[1], item, sections)
        } else if (explain.isQuote1(item)) {
            this.createQuote1(item, sections)
        } else if (explain.isQuote2(item)) {
            this.createQuote2(item, sections)
        } else if (explain.isList(item)) {
            this.createList(item, sections)
        } else if (explain.isCode(item)) {
            this.createCode(item, sections, hljs)
        } else if (explain.isFormula(item)) {
            this.createFormula(item, sections)
        } else if (explain.isTable(item)) {
            this.createTable(item, sections)
        } else if (explain.isDivider(item)) {
            this.createDivider(item, sections)
        } else if (explain.isCenterP(item)) {
            this.createCenterP(item, sections)
        } else if (explain.isLoopH(item)) {
            this.createLoopH(item, sections)
        } else if (explain.isLoopV(item)) {
            this.createLoopV(item, sections)
        } else if (explain.isLoop(item)) {
            this.createLoop(item, sections)
        } else if (explain.isRightP(item)) {
            this.createRightP(item, sections)
        } else {
            this.createP(item, sections)
        }
        if (isEnd) this.createFootNote(explain.getFootNote(), sections)

    }
    ,
    createEleId(eleName) {
        var random = this.createRandom(10)
        return random + (new Date().getTime())
        // var section = document.getElementById("view-section")
        // switch (eleName) {
        //     case ELEMENT_TYPE.H1:
        //     case ELEMENT_TYPE.H2:
        //     case ELEMENT_TYPE.H3:
        //     case ELEMENT_TYPE.H4:
        //     case ELEMENT_TYPE.H5:
        //     case ELEMENT_TYPE.H6:
        //     case ELEMENT_TYPE.P:
        //     case ELEMENT_TYPE.PRE:
        //     case ELEMENT_TYPE.CODE:
        //     case ELEMENT_TYPE.SPAN:
        //     case ELEMENT_TYPE.DIV:
        //     case ELEMENT_TYPE.UL:
        //     case ELEMENT_TYPE.LI:
        //     case ELEMENT_TYPE.BLOCKQUOTE: {
        //         return eleName + "-" + section.querySelectorAll(eleName).length + "-" + random
        //     }
        //     default: {
        //         return eleName + "-" + section.children.length + "-" + random
        //     }
        // }

    }
    ,

    createRandom(len = 5) {
        const $chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
        const maxPos = $chars.length;
        let str = '';
        for (let i = 0; i < len; i++) {
            str += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return str;
    }
    ,
    addToSection(sections, ele) {
        sections.forEach(section => {
            section.append(ele.cloneNode(true))
        })
    }
}
