<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  body{
    padding: 0;
    margin: 0;
    overflow: hidden;
    max-height: 100vh;
  }
</style>
