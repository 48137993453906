const FONT_FAMILY = "font-family:Optima-Regular, Optima, PingFangSC-light, PingFangTC-light, \"PingFang SC\", Cambria, Cochin, Georgia, Times, \"Times New Roman\", serif;"
const FONT_WEIGHT = "font-weight:bold;"
const MARGIN = "margin:10px 0;"
const PADDING = {
    X5: "padding-left:5px"
}
const BOX_SHADOW = "box-shadow: rgba(0, 0, 0, 0.52) 0px 2px 10px;"
const COLOR = {
    FONT: "color:#492872;",
    _020202: "color:#020202;"
}
const STYLE1 = {
    H1: "border-left:5px solid ;word-break:break-all;margin:35px 5px 25px 5px ;font-size:24px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H2: "border-left:4px solid ;word-break:break-all;margin:35px 5px 25px 5px ;font-size:22px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H3: "border-left:4px solid ;word-break:break-all;margin:30px 5px 20px 5px ;font-size:20px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H4: "border-left:3px solid ;word-break:break-all;margin:30px 5px 20px 5px ;font-size:19px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H5: "border-left:2px solid ;word-break:break-all;margin:25px 5px 15px 5px ;font-size:18px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H6: "word-break:break-all;margin:25px 5px 15px 5px ;font-size:17px;" + FONT_FAMILY + FONT_WEIGHT + MARGIN,
    CENTER_H1: "text-align:center;border-bottom:1px solid ;word-break:break-all;margin:30px 5px;font-size:30px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H2: "text-align:center;border-bottom:1px solid ;word-break:break-all;margin:20px 5px;font-size:27px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H3: "text-align:center;border-bottom:1px solid ;word-break:break-all;margin:15px 5px;font-size:24px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H4: "text-align:center;word-break:break-all;margin:15px 5px;font-size:21px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H5: "text-align:center;word-break:break-all;margin:10px 5px;font-size:19px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H6: "word-break:break-all;margin:5px 5px;font-size:17px;" + FONT_FAMILY + FONT_WEIGHT + MARGIN,
    P: "text-indent:20px ;word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:30px;padding:2px 0;" + FONT_FAMILY + MARGIN + COLOR._020202,
    BLOCKQUOTE1: "border-left:8px solid #4385d394;border-radius: 7px;word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;background:#b8ccdb2e;padding: 18px 10px 18px 20px;margin: 20px 0;" + FONT_FAMILY + COLOR._020202,
    BLOCKQUOTE1_2: "border-left:8px solid #4385d394 ;border-radius: 7px;word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;background:#b8ccdb2e;padding: 18px 10px 18px 20px;margin: 5px 0;" + FONT_FAMILY + COLOR._020202,
    BLOCKQUOTE2: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;padding:20px;box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);" + FONT_FAMILY + MARGIN + COLOR._020202,
    BLOCKQUOTE_P: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;padding: 8px 0;line-height:20px;" + FONT_FAMILY + COLOR._020202,
    DIVIDER: "border-bottom:1px solid #02020222;" + MARGIN,
    PRE: "border-radius: 5px; box-shadow: rgba(0, 0, 0, 0.55) 0px 2px 10px;" + BOX_SHADOW + MARGIN,
    CODE: "overflow-x: auto; color: #abb2bf; display: -webkit-box; font-size: 15px; -webkit-overflow-scrolling: touch; background: #282c34; border-radius: 5px;font-family: Consolas,Menlo,Courier;",
    CODE_PREFIX: "display: block;background: url(http://hyz.cool/svg/apple_code_title.svg) 10px 10px / 40px no-repeat rgb(40, 44, 52);height: 30px;margin-bottom: -7px;border-top-left-radius: 5px;border-top-right-radius: 5px;",
    CODE_PREFIX_WECHAT: "display: block;background: url(https://mmbiz.qpic.cn/mmbiz_svg/574VdhMFwaEZwAG5R9lQWiabE1oYxOquSq4pgPjibibmZDgIFiaWhAhDrDhFRRJQ0BkEib8iaFYH6u7MtaF5hTgIL2U2gMmhVTKev3/640?wx_fmt=svg;) 10px 10px / 40px no-repeat rgb(40, 44, 52);height: 30px;margin-bottom: -7px;border-radius: 5px;",
    UL: "padding-left:25px;" + FONT_FAMILY + MARGIN,
    LI: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;" + FONT_FAMILY + MARGIN + COLOR._020202,
    MARK: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;word-wrap: break-word;padding: 2px 4px;border-radius: 4px;margin: 0 2px;color: #1e6bb8;background-color: rgba(27,31,35,.05);" + FONT_FAMILY + MARGIN,
    FIGURE: "display:flex;flex-direction:column;justify-content:center;;align-items:center;text-align: center;position: relative;",
    LINK: "text-decoration: none;color:#38ade4;border-bottom: 1px solid #38ade4;" + FONT_WEIGHT,
    LINK_SUP: "color:#38ade4;" + FONT_WEIGHT,
    IMG: "width:80%;box-shadow:0px 0px 6px 0px #0000006b;margin:10px 0;",
    FIGURE_FIGCAPTION: "margin-top: 5px;text-align: center;color: #888;font-size: 14px;" + FONT_FAMILY,
    CENTER_P: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:25px;" + FONT_FAMILY + MARGIN + COLOR._020202 + "text-align:center;",
    RIGHT_P: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:25px;" + FONT_FAMILY + MARGIN + COLOR._020202 + "text-align:right;",
    TABLE: "width:100%;" + FONT_FAMILY,
    THEAD: FONT_WEIGHT,
    TR: "min-width: 85px;",
    TH: "min-width: 85px;background:#ebecec78;  border-bottom: 1px solid #ddd;margin:10px 0;height:40px;padding: 5px;",
    TD: "min-width: 85px;background:#ebecec78;  border-bottom: 1px solid #ddd;height:40px;padding: 5px;",
    FOOTNOTE: {
        SECTION: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:30px;padding:2px 0;" + FONT_FAMILY + MARGIN + COLOR._020202,
        SPAN: "display: flex;",
        SPAN_SUB: "display: flex;margin-right:5px;word-break: keep-all;",
        P: "margin:5px;",
        P_SPAN: "border-bottom: 1px solid ;" + FONT_WEIGHT,
        EM: "margin:0 10px;"
    },
    LOOP_H:{
        OUT:"display: flex;flex-direction: column;overflow:hidden;"+MARGIN,
        INN:"display:flex;flex-direction:row;overflow-x:scroll;"
    },
    LOOP_V:{
        OUT:"display: flex;flex-direction: column;overflow:hidden;"+MARGIN,
        INN:"display:flex;flex-direction:column;overflow-y:scroll;"
    }
}
const STYLE2 = {
    H1: "border-left:5px solid ;word-break:break-all;margin:35px 5px 25px 5px ;font-size:24px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H2: "border-left:4px solid ;word-break:break-all;margin:35px 5px 25px 5px ;font-size:22px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H3: "border-left:4px solid ;word-break:break-all;margin:30px 5px 20px 5px ;font-size:20px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H4: "border-left:3px solid ;word-break:break-all;margin:30px 5px 20px 5px ;font-size:19px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H5: "border-left:2px solid ;word-break:break-all;margin:25px 5px 15px 5px ;font-size:18px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT + PADDING.X5,
    H6: "word-break:break-all;margin:25px 5px 15px 5px ;font-size:17px;" + FONT_FAMILY + FONT_WEIGHT + MARGIN,
    CENTER_H1: "text-align:center;border-bottom:1px solid ;word-break:break-all;margin:30px 5px;font-size:30px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H2: "text-align:center;border-bottom:1px solid ;word-break:break-all;margin:20px 5px;font-size:27px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H3: "text-align:center;border-bottom:1px solid ;word-break:break-all;margin:15px 5px;font-size:24px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H4: "text-align:center;word-break:break-all;margin:15px 5px;font-size:21px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H5: "text-align:center;word-break:break-all;margin:10px 5px;font-size:19px;" + FONT_FAMILY + FONT_WEIGHT + COLOR.FONT,
    CENTER_H6: "word-break:break-all;margin:5px 5px;font-size:17px;" + FONT_FAMILY + FONT_WEIGHT + MARGIN,
    P: "text-indent:20px ;word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:30px;padding:2px 0;" + FONT_FAMILY + MARGIN + COLOR._020202,
    BLOCKQUOTE1: "border-left:8px solid #509dc15e ;border-radius: 7px;word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;background:#46aada26;padding: 18px 10px 18px 20px;margin: 20px 0;" + FONT_FAMILY + COLOR._020202,
    BLOCKQUOTE1_2: "border-left:8px solid #509dc15e ;border-radius: 7px;word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;background:#c9e5f2;padding: 18px 10px 18px 20px;margin: 5px 0;" + FONT_FAMILY + COLOR._020202,
    BLOCKQUOTE2: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;padding:20px;box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);" + FONT_FAMILY + MARGIN + COLOR._020202,
    BLOCKQUOTE_P: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;padding: 8px 0;line-height:20px;" + FONT_FAMILY + COLOR._020202,
    DIVIDER: "border-bottom:1px solid #02020222;" + MARGIN,
    PRE: "border-radius: 5px; box-shadow: rgba(0, 0, 0, 0.55) 0px 2px 10px;" + BOX_SHADOW + MARGIN,
    CODE: "overflow-x: auto; padding: 16px; color: #abb2bf; display: -webkit-box; font-size: 15px; -webkit-overflow-scrolling: touch; padding-top: 15px; background: #282c34; border-radius: 5px;" + FONT_FAMILY,
    CODE_PREFIX: "display: block;background: url(http://hyz.cool/svg/apple_code_title.svg) 10px 10px / 40px no-repeat rgb(40, 44, 52);height: 30px;margin-bottom: -7px;border-top-left-radius: 5px;border-top-right-radius: 5px;",
    CODE_PREFIX_WECHAT: "display: block;background: url(https://mmbiz.qpic.cn/mmbiz_svg/574VdhMFwaEZwAG5R9lQWiabE1oYxOquSq4pgPjibibmZDgIFiaWhAhDrDhFRRJQ0BkEib8iaFYH6u7MtaF5hTgIL2U2gMmhVTKev3/640?wx_fmt=svg;) 10px 10px / 40px no-repeat rgb(40, 44, 52);height: 30px;margin-bottom: -7px;border-radius: 5px;",
    UL: "padding-left:25px;" + FONT_FAMILY + MARGIN,
    LI: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;" + FONT_FAMILY + MARGIN + COLOR._020202,
    MARK: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;word-wrap: break-word;padding: 2px 4px;border-radius: 4px;margin: 0 2px;color: #1e6bb8;background-color: rgba(27,31,35,.05);" + FONT_FAMILY + MARGIN,
    FIGURE: "display:flex;flex-direction:column;justify-content:center;;align-items:center;text-align: center;position: relative;",
    LINK: "color:#38ade4;border-bottom: 1px solid #38ade4;" + FONT_WEIGHT,
    LINK_SUP: "color:#38ade4;" + FONT_WEIGHT,
    IMG: "width:80%;",
    FIGURE_FIGCAPTION: "margin-top: 5px;text-align: center;color: #888;font-size: 14px;" + FONT_FAMILY,
    CENTER_P: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:25px;" + FONT_FAMILY + MARGIN + COLOR._020202 + "text-align:center;",
    RIGHT_P: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:25px;" + FONT_FAMILY + MARGIN + COLOR._020202 + "text-align:right;",
    TABLE: "width:90%;" + FONT_FAMILY,
    THEAD: FONT_WEIGHT,
    TR: "min-width: 85px;",
    TH: "min-width: 85px; background:#a9e5f378; border-bottom: 1px solid #ddd;margin:10px 0;height:40px;padding: 5px;",
    TD: "min-width: 85px; background:#a9e5f378;  border-bottom: 1px solid #ddd;height:40px;padding: 5px;",
    FOOTNOTE: {
        SECTION: "word-break:break-all;margin:5px;font-size:16px;letter-spacing:1px;line-height:30px;padding:2px 0;" + FONT_FAMILY + MARGIN + COLOR._020202,
        SPAN: "display: flex;",
        SPAN_SUB: "display: flex;margin-right:5px;word-break: keep-all;",
        P: "",
        P_SPAN: "border-bottom: 1px solid ;" + FONT_WEIGHT,
        EM: "margin:0 10px;"
    }
}

export const STYLE = STYLE1

