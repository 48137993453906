import {createApp} from 'vue'
import {createRouter,createWebHistory} from "vue-router"
import App from './App.vue'
import {routes} from "./router/router"
import 'element-plus/dist/index.css'

const router = createRouter({
    history: createWebHistory(),
    routes
})
const app = createApp(App)
app.use(router)
app.mount('#app')
