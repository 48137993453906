export  const md =`​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​![Logo](https://hyz.cool/public/R/img/logo.png)

请阅读下方文本熟悉工具使用方法，本文可直接拷贝到微信中预览。

# 1 Lib Markdown 简介 

- 自定义样式的 Markdown 编辑器
- 支持微信公众号
- 欢迎扫码关注微信公众号

![续加仪](https://files.hyz.cool/files/articles/096fefdf6c3e683b83b8ad0886c8c38a.jpg,300*300)

## 2 通用语法

### 2.1 标题

在文字写书写不同数量的\`#\`可以完成不同的标题，如下：

# 一级标题

## 二级标题

### 三级标题

### 2.2 无序列表

无序列表的使用，在符号\`-\`后加空格使用。如果要控制列表的层级，则需要在符号\`-\`前使用空格。如下：

- 无序列表 1
- 无序列表 2
  - 无序列表 2.1
    - 无序列表 2.2

**微信最多支持到二级列表**。

### 2.3 有序列表

有序列表的使用，在数字及符号\`.\`后加空格后输入内容，如下：

1. 有序列表 1
2. 有序列表 2
3. 有序列表 3

### 2.4 粗体和斜体

粗体的使用是在需要加粗的文字前后各加两个\`*\`。

而斜体的使用则是在需要斜体的文字前后各加一个\`*\`。

如果要使用粗体和斜体，那么就是在需要操作的文字前后加三个\`*\`。如下：   

**这个是粗体**

*这个是斜体*

***这个是粗体加斜体***

### 2.5 链接

微信公众号仅支持公众号文章链接，即域名为\`https://mp.weixin.qq.com/\`的合法链接。使用方法如下所示：
[Web Socket +Rtc 实现视频通话（一）](https://mp.weixin.qq.com/s?__biz=MzUyMDc2MzI1Ng==&mid=2247489527&idx=1&sn=e9e5376eea9d4203d979dd94542a8698&chksm=f9e435e0ce93bcf6b85243c709a2505c2f41b894f4774b2da523c14c7ad62c4205f5b94359d6&token=1638205154&lang=zh_CN&scene=21#wechat_redirect)

### 2.6 引用

引用的格式是在符号 \`>\` 后面书写文字，文字的内容可以包含标题、链接、图片、粗体和斜体等。

一级引用如下：

> 一级引用示例
> ![秋天](https://files.hyz.cool/files/photos/b71c7eff79fb1a0a7395f8a7112f82c0.jpg,400*200)

当使用多个 \`>\` 符号时，就会变成多级引用

二级引用如下：

>>二级引用示例
>> ![秋天](https://files.hyz.cool/files/photos/b71c7eff79fb1a0a7395f8a7112f82c0.jpg,350*200)


### 2.7 分割线

可以在一行中用三个以上的减号来建立一个分隔线，同时需要在分隔线的上面空一行。如下：

---

### 2.8 删除线

删除线的使用，在需要删除的文字前后各使用两个\`~\`，如下：

~~这是要被删除的内容。~~

### 2.9 表格

可以使用冒号来定义表格的对齐方式，如下：

| 姓名 | 年龄 | 工作 |
| :--------- | :--: | -----------: |
| 小可爱 | 18 | 吃可爱多 |
| 小小勇敢 | 20 | 爬棵勇敢树 |
| 小小小机智 | 22 | 看一本机智书 |

宽度过长的表格可以滚动，可在自定义主题中调节宽度：

| 姓名 | 年龄 | 工作 | 邮箱 | 手机 |
| :--------- | :--: | -----------: | :-------------: | :---------: |
| 小可爱 | 18 | 吃可爱多 | lovely@test.com | 18812345678 |
| 小小勇敢 | 20 | 爬棵勇敢树 | brave@test.com | 17712345678 |
| 小小小机智 | 22 | 看一本机智书 | smart@test.com | 16612345678 |

### 2.10 图片

插入图片，如果是行内图片则无图例，否则有图例，格式如下：

![这里写图片描述](https://files.hyz.cool/files/photos/7d0f4f41b627a133d2bceac049f581c2.jpg)

可以通过在图片尾部添加宽度和高度控制图片大小，用法如下：


![同时设置宽度和高度](https://files.hyz.cool/files/photos/1142ecc7d54bed6c9d0d9678a1d575cb.jpg,320x200)

![只设置宽度，推荐使用小数](https://files.hyz.cool/files/photos/9259ee7a6e3c2b35a3afe5843fff4350.jpg,0.9)

该语法比较特殊，其他 Markdown 编辑器不完全通用。


**注：仅支持 https 的图片，图片粘贴到微信、知乎或掘金时会自动上传其服务器，不必担心使用上述图床会导致图片丢失**。

图片还可以和链接嵌套使用，能够实现推荐卡片的效果，用法如下：

[![续加仪](https://files.hyz.cool/files/photos/b71c7eff79fb1a0a7395f8a7112f82c0.jpg)](https://app.hyz.cool)

添加图片的方式是右键单击编辑区域，此处的添加图片不涉及网络，仅转为base64缓存，压缩至1Mb，刷新即丢失。

## 3. 特殊语法

### 3.1 脚注

> 支持平台：微信公众号

脚注与链接的区别如下所示：

\`\`\`markdown
链接：[文字](链接)
脚注：[文字](脚注解释 "脚注名字")
\`\`\`

有人认为在[大前端时代](https://en.wikipedia.org/wiki/Front-end_web_development "Front-end web development")的背景下，移动端开发（Android、IOS）将逐步退出历史舞台。

[全栈工程师](是指掌握多种技能，并能利用多种技能独立完成产品的人。 "什么是全栈工程师")在业务开发流程中起到了至关重要的作用。

脚注内容请拉到最下面观看。

### 3.2 代码块

> 支持平台：微信公众号、知乎。

如果在一个行内需要引用代码，只要用反引号引起来就好，如下：

Use the \`printf()\` function.

在需要高亮的代码块的前一行及后一行使用三个反引号，同时**第一行反引号后面表示代码块所使用的语言**，如下：

\`\`\`java
// FileName: HelloWorld.java
  insertText(obj, str) {
                if (document.selection) {
                    var sel = document.selection.createRange();
                    sel.text = str;
                } else if (typeof obj.selectionStart === 'number' && typeof obj.selectionEnd === 'number') {
                    var startPos = obj.selectionStart,
                        endPos = obj.selectionEnd,
                        cursorPos = startPos,
                        tmpStr = obj.value;
                    obj.value = tmpStr.substring(0, startPos) + str + tmpStr.substring(endPos, tmpStr.length);
                    cursorPos += str.length;
                    obj.selectionStart = obj.selectionEnd = cursorPos;
                } else {
                    obj.value += str;
                }
  }
\`\`\`

如果想要更换代码主题，可在上方挑选，不支持代码主题自定义。

其中**微信代码主题与微信官方一致**，有以下注意事项：



### 3.3  注音符号

> 支持平台：微信公众号。

支持注音符号，用法如下：

\\Lib Markdown 这么好用，简直是{喜大普奔|hē hē hē hē}呀！

### 3.4 横屏滑动幻灯片

> 支持平台：微信公众号。

通过\`L!\\[](url)!\\[](url)\`这种语法设置横屏滑动滑动片，具体用法如下：

L ![](https://files.hyz.cool/files/photos/7d0f4f41b627a133d2bceac049f581c2.jpg) ![](https://files.hyz.cool/files/photos/1142ecc7d54bed6c9d0d9678a1d575cb.jpg)![](https://files.hyz.cool/files/photos/9259ee7a6e3c2b35a3afe5843fff4350.jpg)![](https://files.hyz.cool/files/photos/b71c7eff79fb1a0a7395f8a7112f82c0.jpg)

通过\`LV250 !\\[](url)!\\[](url)\`这种语法设置竖屏滑动滑动片，\`250\`表示高度，具体用法如下：

LV250 ![](https://files.hyz.cool/files/photos/000fb3eb3f8cb63761a496de2d3fe306.png)![](https://files.hyz.cool/files/photos/7d0f4f41b627a133d2bceac049f581c2.jpg) ![](https://files.hyz.cool/files/photos/1142ecc7d54bed6c9d0d9678a1d575cb.jpg)![](https://files.hyz.cool/files/photos/9259ee7a6e3c2b35a3afe5843fff4350.jpg)![](https://files.hyz.cool/files/photos/b71c7eff79fb1a0a7395f8a7112f82c0.jpg)

## 4 其他语法

### 4.1 HTML

支持原生 HTML 语法，请写内联样式，如下：

<span style="display:block;text-align:right;color:orangered;">橙色居右</span>
<span style="display:block;text-align:center;color:orangered;">橙色居中</span>

### 4.2  更多文档

更多文档请参考 [续加仪](https://www.hyz.cool "更多文档")`


    "​​​​​​​​​​​​​​​\n" +
    ">  ![Logo](https://hyz.cool/public/R/img/logo.png)\n" +
    "\n" +
    "请阅读下方文本熟悉工具使用方法，本文可直接拷贝到微信中预览。\n" +
    "\n" +
    "# 1 Lib Markdown 简介 \n" +
    "\n" +
    "- 自定义样式的 Markdown 编辑器\n" +
    "- 支持微信公众号\n" +
    "- 欢迎扫码关注微信公众号\n" +
    "\n" +
    "![续加仪](https://files.hyz.cool/files/articles/096fefdf6c3e683b83b8ad0886c8c38a.jpg,300*300)\n" +
    "\n" +
    "## 2 通用语法\n" +
    "\n" +
    "### 2.1 标题\n" +
    "\n" +
    "在文字写书写不同数量的`#`可以完成不同的标题，如下：\n" +
    "\n" +
    "# 一级标题\n" +
    "\n" +
    "## 二级标题\n" +
    "\n" +
    "### 三级标题\n" +
    "\n" +
    "### 2.2 无序列表\n" +
    "\n" +
    "无序列表的使用，在符号`-`后加空格使用。如果要控制列表的层级，则需要在符号`-`前使用空格。如下：\n" +
    "\n" +
    "- 无序列表 1\n" +
    "- 无序列表 2\n" +
    "  - 无序列表 2.1\n" +
    "    - 无序列表 2.2\n" +
    "\n" +
    "**微信最多支持到二级列表**。\n" +
    "\n" +
    "### 2.3 有序列表\n" +
    "\n" +
    "有序列表的使用，在数字及符号`.`后加空格后输入内容，如下：\n" +
    "\n" +
    "1. 有序列表 1\n" +
    "2. 有序列表 2\n" +
    "3. 有序列表 3\n" +
    "\n" +
    "### 2.4 粗体和斜体\n" +
    "\n" +
    "粗体的使用是在需要加粗的文字前后各加两个`*`。\n" +
    "\n" +
    "而斜体的使用则是在需要斜体的文字前后各加一个`*`。\n" +
    "\n" +
    "如果要使用粗体和斜体，那么就是在需要操作的文字前后加三个`*`。如下：   \n" +
    "\n" +
    "**这个是粗体**\n" +
    "\n" +
    "*这个是斜体*\n" +
    "\n" +
    "***这个是粗体加斜体***\n" +
    "\n" +
    "### 2.5 链接\n" +
    "\n" +
    "微信公众号仅支持公众号文章链接，即域名为`https://mp.weixin.qq.com/`的合法链接。使用方法如下所示：\n" +
    "[Web Socket +Rtc 实现视频通话（一）](https://mp.weixin.qq.com/s?__biz=MzUyMDc2MzI1Ng==&mid=2247489527&idx=1&sn=e9e5376eea9d4203d979dd94542a8698&chksm=f9e435e0ce93bcf6b85243c709a2505c2f41b894f4774b2da523c14c7ad62c4205f5b94359d6&token=1638205154&lang=zh_CN&scene=21#wechat_redirect)\n" +
    "\n" +
    "### 2.6 引用\n" +
    "\n" +
    "引用的格式是在符号 `>` 后面书写文字，文字的内容可以包含标题、链接、图片、粗体和斜体等。\n" +
    "\n" +
    "一级引用如下：\n" +
    "\n" +
    "> 一级引用示例\n" +
    "> ![Logo](https://hyz.cool/public/R/img/logo.png)\n" +
    "\n" +
    "当使用多个 `>` 符号时，就会变成多级引用\n" +
    "\n" +
    "二级引用如下：\n" +
    "\n" +
    ">>二级引用示例\n" +
    ">> ![Logo](https://hyz.cool/public/R/img/logo.png)\n" +
    "\n" +
    "三级引用如下：\n" +
    "\n" +
    ">>> 三级引用示例\n" +
    ">>> ![Logo](https://hyz.cool/public/R/img/logo.png)\n" +
    "\n" +
    "### 2.7 分割线\n" +
    "\n" +
    "可以在一行中用三个以上的减号来建立一个分隔线，同时需要在分隔线的上面空一行。如下：\n" +
    "\n" +
    "---\n" +
    "\n" +
    "### 2.8 删除线\n" +
    "\n" +
    "删除线的使用，在需要删除的文字前后各使用两个`~`，如下：\n" +
    "\n" +
    "~~这是要被删除的内容。~~\n" +
    "\n" +
    "### 2.9 表格\n" +
    "\n" +
    "可以使用冒号来定义表格的对齐方式，如下：\n" +
    "\n" +
    "| 姓名 | 年龄 | 工作 |\n" +
    "| :--------- | :--: | -----------: |\n" +
    "| 小可爱 | 18 | 吃可爱多 |\n" +
    "| 小小勇敢 | 20 | 爬棵勇敢树 |\n" +
    "| 小小小机智 | 22 | 看一本机智书 |\n" +
    "\n" +
    "宽度过长的表格可以滚动，可在自定义主题中调节宽度：\n" +
    "\n" +
    "| 姓名 | 年龄 | 工作 | 邮箱 | 手机 |\n" +
    "| :--------- | :--: | -----------: | :-------------: | :---------: |\n" +
    "| 小可爱 | 18 | 吃可爱多 | lovely@test.com | 18812345678 |\n" +
    "| 小小勇敢 | 20 | 爬棵勇敢树 | brave@test.com | 17712345678 |\n" +
    "| 小小小机智 | 22 | 看一本机智书 | smart@test.com | 16612345678 |\n" +
    "\n" +
    "### 2.10 图片\n" +
    "\n" +
    "插入图片，如果是行内图片则无图例，否则有图例，格式如下：\n" +
    "\n" +
    "![这里写图片描述](http://hyz.cool/public/R/img/logo.png)\n" +
    "\n" +
    "可以通过在图片尾部添加宽度和高度控制图片大小，用法如下：\n" +
    "\n" +
    "![同时设置宽度和高度](http://hyz.cool/public/R/img/logo.png,150x200)\n" +
    "\n" +
    "![只设置宽度，推荐使用小数](http://hyz.cool/public/R/img/logo.png,0.5)\n" +
    "\n" +
    "该语法比较特殊，其他 Markdown 编辑器不完全通用。\n" +
    "\n" +
    "\n" +
    "**注：仅支持 https 的图片，图片粘贴到微信、知乎或掘金时会自动上传其服务器，不必担心使用上述图床会导致图片丢失**。\n" +
    "\n" +
    "图片还可以和链接嵌套使用，能够实现推荐卡片的效果，用法如下：\n" +
    "\n" +
    "[![续加仪](http://hyz.cool/public/R/img/logo.png)](https://app.hyz.cool)\n" +
    "\n" +
    "## 3. 特殊语法\n" +
    "\n" +
    "### 3.1 脚注\n" +
    "\n" +
    "> 支持平台：微信公众号\n" +
    "\n" +
    "脚注与链接的区别如下所示：\n" +
    "\n" +
    "```markdown\n" +
    "链接：[文字](链接)\n" +
    "脚注：[文字](脚注解释 \"脚注名字\")\n" +
    "```\n" +
    "\n" +
    "有人认为在[大前端时代](https://en.wikipedia.org/wiki/Front-end_web_development \"Front-end web development\")的背景下，移动端开发（Android、IOS）将逐步退出历史舞台。\n" +
    "\n" +
    "[全栈工程师](是指掌握多种技能，并能利用多种技能独立完成产品的人。 \"什么是全栈工程师\")在业务开发流程中起到了至关重要的作用。\n" +
    "\n" +
    "脚注内容请拉到最下面观看。\n" +
    "\n" +
    "### 3.2 代码块\n" +
    "\n" +
    "> 支持平台：微信公众号、知乎。\n" +
    "\n" +
    "如果在一个行内需要引用代码，只要用反引号引起来就好，如下：\n" +
    "\n" +
    "Use the `printf()` function.\n" +
    "\n" +
    "在需要高亮的代码块的前一行及后一行使用三个反引号，同时**第一行反引号后面表示代码块所使用的语言**，如下：\n" +
    "\n" +
    "```java\n" +
    "// FileName: HelloWorld.java\n" +
    "public class HelloWorld {\n" +
    "\n" +
    "// Java 入口程序，程序从此入口\n" +
    "  public  static  void main(String[] args) {\n" +
    "     System.out.println(\"Hello,World!\"); // 向控制台打印一条语句\n" +
    "   }\n" +
    "}\n" +
    "```\n" +
    "\n" +
    "如果想要更换代码主题，可在上方挑选，不支持代码主题自定义。\n" +
    "\n" +
    "其中**微信代码主题与微信官方一致**，有以下注意事项：\n" +
    "\n" +
    "\n" +
    "### 3.3 数学公式\n" +
    "\n" +
    "> 支持平台：微信公众号、知乎。\n" +
    "\n" +
    "行内公式使用方法，比如这个化学公式：$\\ce{Hg^2+ ->[I-] HgI2 ->[I-] [Hg^{II}I4]^2-}$\n" +
    "\n" +
    "块公式使用方法如下：\n" +
    "\n" +
    "$$\n" +
    "H(D_2) = -\\left(\\frac{2}{4}\\log_2 \\frac{2}{4} + \\frac{2}{4}\\log_2 \\frac{2}{4}\\right) = 1\n" +
    "$$\n" +
    "\n" +
    "矩阵：\n" +
    "\n" +
    "$$\n" +
    "\\begin{pmatrix}\n" +
    "1 & a_1 & a_1^2 & \\cdots & a_1^n \\\\\n" +
    "1 & a_2 & a_2^2 & \\cdots & a_2^n \\\\\n" +
    "\\vdots & \\vdots & \\vdots & \\ddots & \\vdots \\\\\n" +
    "1 & a_m & a_m^2 & \\cdots & a_m^n \\\\\n" +
    "\\end{pmatrix}\n" +
    "$$\n" +
    "\n" +
    "公式由于微信不支持，目前的解决方案是转成 svg 放到微信中，无需调整，矢量不失真。\n" +
    "\n" +
    "目前测试如果公式量过大，在 Chrome 下会存在粘贴后无响应，但是在 Firefox 中始终能够成功。\n" +
    "\n" +
    "### 3.4 TOC\n" +
    "\n" +
    "> 支持平台：微信公众号、知乎。\n" +
    "\n" +
    "TOC 全称为 Table of Content，列出全部标题。由于示例标题过多，需要使用将下方代码段去除即可。\n" +
    "\n" +
    "```\n" +
    "[TOC]\n" +
    "```\n" +
    "\n" +
    "由于微信只支持到二级列表，本工具仅支持二级标题和三级标题的显示。\n" +
    "\n" +
    "### 3.5 注音符号\n" +
    "\n" +
    "> 支持平台：微信公众号。\n" +
    "\n" +
    "支持注音符号，用法如下：\n" +
    "\n" +
    "\\Lib Markdown 这么好用，简直是{喜大普奔|hē hē hē hē}呀！\n" +
    "\n" +
    "### 3.6 横屏滑动幻灯片\n" +
    "\n" +
    "> 支持平台：微信公众号。\n" +
    "\n" +
    "通过`L!\\[](url)!\\[](url)`这种语法设置横屏滑动滑动片，具体用法如下：\n" +
    "\n" +
    "L ![](https://dl.zhutix.net/2019/08/eXPerience_00.png) ![](https://dl.zhutix.net/2017/05/6587.jpg)![](https://dl.zhutix.net/2021/09/78585.jpg)![](https://dl.zhutix.net/2021/08/Sporty-Zero-Two.gif)\n" +
    "\n" +
    "通过`LV250 !\\[](url)!\\[](url)`这种语法设置竖屏滑动滑动片，`250`表示高度，具体用法如下：\n" +
    "\n" +
    "LV250 ![](https://dl.zhutix.net/2019/08/eXPerience_00.png) ![](https://dl.zhutix.net/2017/05/6587.jpg)![](https://dl.zhutix.net/2021/09/78585.jpg)![](https://dl.zhutix.net/2021/08/Sporty-Zero-Two.gif)\n" +
    "\n" +
    "## 4 其他语法\n" +
    "\n" +
    "### 4.1 HTML\n" +
    "\n" +
    "支持原生 HTML 语法，请写内联样式，如下：\n" +
    "\n" +
    "<span style=\"display:block;text-align:right;color:orangered;\">橙色居右</span>\n" +
    "<span style=\"display:block;text-align:center;color:orangered;\">橙色居中</span>\n" +
    "\n" +
    "### 4.2 UML\n" +
    "\n" +
    "不支持，推荐使用开源工具`https://draw.io/`制作后再导入图片\n" +
    "\n" +
    "### 4.3 更多文档\n" +
    "\n" +
    "更多文档请参考 [续加仪](https://app.hyz.cool \"更多文档\")"





