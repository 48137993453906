<template>
    <div class="box">
        <input type="file" id="file" @change="selectFile" accept="image/png,image/jpg,image/jpeg,image/gif" multiple/>
        <div class="img-grid">
            <div class="img-item" v-for="item in imgList">
                <img :src="item.base64">
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import * as imageConversion from "image-conversion";

    export default {
        data() {
            return {
                imgList: []
            }
        },
        methods: {
            selectFile() {
                //压缩后的最大尺寸
                var MAX_SIZE = 1024
                //获取文件列表
                var fileInputList = document.getElementById("file").files
                var that = this
                //初始化数据
                that.imgList = []
                //开始写入数据
                setImgData(fileInputList)

                //递归顺序写入数据
                function setImgData(input) {
                    var index = that.imgList.length
                    if (input.length > index) {
                        console.log(fileInputList[index].name, "压缩前：", (fileInputList[index].size / 1024).toFixed(0) + "KB")
                        var name = fileInputList[index].name
                        compress(fileInputList[index]).then(res => {
                            blobToDataUrl(res, base64 => {
                                console.log(name, "压缩后：", (res.size / 1024).toFixed(0) + "KB")
                                that.imgList.push({
                                    name,
                                    base64
                                })
                                if (input.length > index) {
                                    //递归
                                    setImgData(input)
                                }
                            })
                        })
                    }
                }

                //压缩
                function compress(target) {
                    return new Promise(resolve => {
                        if (target.size / 1024 < MAX_SIZE) {
                            resolve(target);
                        }
                        // 压缩到MAX_SIZE KB,这里的MAX_SIZE就是要压缩的大小,可自定义
                        imageConversion
                            .compressAccurately(target, MAX_SIZE)
                            .then(res => {
                                resolve(res);
                            });
                    });
                }

                //blob转为base64
                function blobToDataUrl(blob, output) {
                    let reader = new FileReader()
                    reader.onload = function (evt) {
                        let base64 = evt.target.result
                        output(base64)
                    }
                    reader.readAsDataURL(blob)
                }

            }
        }
    }
</script>

<style scoped>
    body {
        margin: 0;
        padding: 0;
        overflow: auto !important;
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box input {
        margin: 20px;
    }

    .box .img-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .box .img-grid .img-item {
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box img {
        width: 200px;
        height: 120px;
    }

    .box span {
        margin: 5px;
    }
</style>
