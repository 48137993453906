import Editor from "../pages/editor/editor"
import View from "../pages/img/view"
import Compress from "../pages/img/compress"

export const routes=[
    {
        path:"/",
        component:Editor
    }, {
        path:"/view",
        component:View
    },{
        path:"/compress",
        component:Compress
    },
]
