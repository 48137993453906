import {STYLE} from "./style";

import katex from "katex"
import 'katex/dist/contrib/mhchem.js' // modify katex module

const prefix = "@##@@##@"
const endfix = "&@@#@@&"
const REPLACE = {
    X: ['*', 'aa'],
    Z: ['~', 'bb']
}
var footNoteList = []
var imageList = []
export default {

    getTextArray(str) {
        footNoteList = []
        var arr = str.split("\n")

        var arrRes = []

        var tempCode = []
        var canCode = false


        var tempList = []
        var canList = false


        var tempQuote = []
        var canQuote = false

        var tempTable = []
        var canTable = false

        var tempFormula = []
        var canFormula = false

        var that = this
        arr.forEach(item => {
                //判断是否代码区
                if (that.isCode(item)) {//是代码区标志
                    canCode = !canCode
                    if (!canCode) {
                        arrRes.push(tempCode.join("\n"))
                        tempCode = []
                    } else {
                        tempCode.push("```")
                    }
                } else {//不是代码区标志--> 代码区||非代码区
                    if (canCode) {//代码区
                        tempCode.push(item)//添加该行到代码区
                    } else {//非代码区
                        //判断是否l列表区
                        if (that.isList(item)) {//是列表区标志
                            if (!canList) {
                                canList = !canList
                            }
                            tempList.push(item)//添加该行到列表区
                        } else {//不是列表区标志
                            if (canList) {
                                arrRes.push(tempList.join("\n"))
                                canList = false
                                tempList = []
                            }
                            //判断是否是引用区
                            if (that.isQuote(item)) {//是引用区
                                if (!canQuote) {
                                    canQuote = !canCode
                                }
                                tempQuote.push(item)
                            } else {//不是引用区
                                if (canQuote) {
                                    canQuote = false
                                    arrRes.push(tempQuote.join("\n"))
                                    tempQuote = []
                                }
                                //判断是否是表格区
                                if (that.isTable(item)) {//是表格区
                                    if (!canTable) {
                                        canTable = !canTable
                                    }
                                    tempTable.push(item)
                                } else {//不是表格区
                                    if (canTable) {
                                        canTable = false
                                        arrRes.push(tempTable.join("\n"))
                                        tempTable = []
                                    }
                                    //判断是否是公式区
                                    if (that.isFormula(item)) {//是公式区标志
                                        canFormula = !canFormula
                                        if (!canFormula) {
                                            tempFormula.push(item.substr(0, item.length - 2))
                                            arrRes.push(tempFormula.join("\n"))
                                            tempFormula = []
                                        } else {
                                            tempFormula.push(item)
                                        }
                                    } else {
                                        if (canFormula) {
                                            tempFormula.push(item)
                                        } else {
                                            arrRes.push(item)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        )

        if (tempCode.length != 0 && canCode) {
            arrRes.push(tempCode.join("\n"))
        }
        if (tempList.length != 0 && canList) {
            arrRes.push(tempList.join("\n"))
        }

        if (tempQuote.length != 0 && canQuote) {
            arrRes.push(tempQuote.join("\n"))
        }
        if (tempTable.length != 0 && canTable) {
            arrRes.push(tempTable.join("\n"))
        }
        if (tempFormula.length != 0 && canFormula) {
            arrRes.push(tempFormula.join("\n"))
        }
        return arrRes
    },
    dealLine(str) {
        if (str == null) {
            return ""
        }
        str = this.dealZeroSpace(str)
        if (str.indexOf("$") >= 0) {
            str = this.dealFormulaInline(str, katex)
        }
        if (str.indexOf('\\') >= 0) {
            str = this.dealKeep(str, "keep")
        }
        if (str.indexOf('[') >= 0 && str.indexOf(']') >= 0 && str.indexOf('(') >= 0 && str.indexOf(')') >= 0) {
            if (str.indexOf('!') >= 0) {
                str = this.dealImage(str)
            }
            str = this.dealLink(str)
        }

        if (str.indexOf('{') >= 0) {
            str = this.dealPhonetic(str)
        }

        if (str.indexOf('\*\*') >= 0) {
            str = this.dealStrong(str)
        }
        if (str.indexOf('~~') >= 0) {
            str = this.dealDelete(str)
        }
        if (str.indexOf('`') >= 0) {
            str = this.dealMark(str)
        }
        if (str.indexOf('\*') >= 0) {
            str = this.dealItalic(str)
        }
        if (str.indexOf(prefix) >= 0 && str.indexOf(endfix) >= 0) {
            str = this.dealKeep(str)
        }
        return str
    },
    dealLoopLine(str){
        if (str.indexOf('!') >= 0) {
            str = this.dealLoopImage(str)
        }
        return str
    },
    dealZeroSpace(str) {
        str = encodeURIComponent(str)
        // if (str.indexOf("%E2%80%8B") > -1) {
        //     str = str.replace('%E2%80%8B', '')
        //     return this.dealZeroSpace(decodeURIComponent(str))
        // }
        // else if (str.indexOf("%A0%A0") > -1) {
        //     str = str.replace('%A0%A0', '%A0')
        //     return this.dealZeroSpace(decodeURIComponent(str))
        // }
        return decodeURIComponent(str)
    },
    dealLink(str) {
        var strRes = ""
        var index = 0
        str.replace(/\[[^\]\[]*\]\([^\)]*\)/gi, function (match, position) {
            strRes += str.substring(index, position)
            var link = match.match(/\((.*?)\)/)[1]
            var text = match.match(/\[(.*?)\]/)[1]

            var linkArr = link.split(" ")

            if (linkArr.length >= 2) {
                footNoteList.push(".content.=" + linkArr[1] + ".link.=" + linkArr[0])
                strRes += " <span style='" + STYLE.LINK + "'>" + text + "</span><sup style='" + STYLE.LINK_SUP + "'>[" + footNoteList.length + "]</sup>"

            } else {
                strRes += "<a href='" + linkArr[0] + "' style='" + STYLE.LINK + "' >" + text + "</a>"
            }
            index = position + match.length
        })
        strRes += str.substring(index)

        return strRes
    },
    dealImage(str) {
        var strRes = ""
        var index = 0
        var that = this

        function dealStr(char, string, type) {
            if (string == null) {
                return ""
            }
            var target, replacer
            if (type == "keep") {
                target = char
                replacer = that.encode(char)
            } else {
                target = prefix + encodeURIComponent(char) + endfix
                replacer = char
            }
            if (string.indexOf(target) >= 0) {
                return dealStr(char, string.replace(target, replacer), type)
            } else {
                return string
            }
        }

        str.replace(/!\[[^\]\[]*\]\([^\)]*\)/gi, function (match, position) {
            var des = dealStr('`', match.match(/\[(.*?)\]/)[1], "keep")
            des = dealStr('*', des, "keep")
            des = dealStr('~', des, "keep")
            strRes += str.substring(index, position)
            var src = match.match(/\((.*?)\)/)[1].split(",")
            var style = ""
            if (src.length >= 2) {
                var size = src[1]
                if (size.indexOf("\*") > 0) {
                    style = "width:" + size.split("\*")[0] + "px;height:" + size.split("\*")[1] + "px;"
                } else if (size.indexOf("x") > 0) {
                    style = "width:" + size.split("x")[0] + "px;height:" + size.split("x")[1] + "px;"
                } else if (parseFloat(size) < 1) {
                    style = "width:" + parseFloat(size) * 100 + "%;"
                }
            }
            if (src.length >= 3) {
                var border_r = src[2]
                style += "border-radius:" + border_r + "px;"
            }
            strRes += "<figure style='" + STYLE.FIGURE + "'>" +
                "<img src='" + (src[0].indexOf("P") === 0 ? that.getImage(parseInt(src[0].substring(1)) - 1) : dealStr("\*", src[0], "keep")) + "'  alt='" + des + "'style='" + STYLE.IMG + style + "'/>" +
                "<figcaption style='" + STYLE.FIGURE_FIGCAPTION + "'>" + des + "</figcaption>" +
                "</figure>"
            index = position + match.length
        })
        strRes += str.substring(index)
        return strRes
    },
    dealLoopImage(str){
        var strRes = ""
        var index = 0
        var that = this

        function dealStr(char, string, type) {
            if (string == null) {
                return ""
            }
            var target, replacer
            if (type == "keep") {
                target = char
                replacer = that.encode(char)
            } else {
                target = prefix + encodeURIComponent(char) + endfix
                replacer = char
            }
            if (string.indexOf(target) >= 0) {
                return dealStr(char, string.replace(target, replacer), type)
            } else {
                return string
            }
        }

        str.replace(/!\[[^\]\[]*\]\([^\)]*\)/gi, function (match, position) {
            var des = dealStr('`', match.match(/\[(.*?)\]/)[1], "keep")
            des = dealStr('*', des, "keep")
            des = dealStr('~', des, "keep")
            strRes += str.substring(index, position)
            var src = match.match(/\((.*?)\)/)[1].split(",")
            var style = ""
            if (src.length >= 2) {
                var size = src[1]
                if (size.indexOf("\*") > 0) {
                    style = "width:" + size.split("\*")[0] + "px;height:" + size.split("\*")[1] + "px;"
                } else if (size.indexOf("x") > 0) {
                    style = "width:" + size.split("x")[0] + "px;height:" + size.split("x")[1] + "px;"
                } else if (parseFloat(size) < 1) {
                    style = "width:" + parseFloat(size) * 100 + "%;"
                }
            }
            if (src.length >= 3) {
                var border_r = src[2]
                style += "border-radius:" + border_r + "px;"
            }
            strRes += "<img src='" + (src[0].indexOf("P") === 0 ? that.getImage(parseInt(src[0].substring(1)) - 1) : dealStr("\*", src[0], "keep")) + "'  alt='" + des + "'style='box-shadow: inset 0 0 18px 2px;width: 100%;" + style + "'>"

            index = position + match.length
        })
        strRes += str.substring(index)
        return strRes
    },

    dealStrong(str) {
        if (str == null) {
            return ""
        }
        var strRes = ""
        var index = 0
        str.replace(/\*\*[^\*]*\*{0,1}[^\*]*\*\*/gi, function (match, position) {
            strRes += str.substring(index, position)
            strRes += "<strong>" + match.substr(2, match.length - 4) + "</strong>"
            index = position + match.length
        })
        strRes += str.substring(index)
        return strRes
    }
    ,
    dealDelete(str) {
        if (str == null) {
            return ""
        }
        var strRes = ""
        var index = 0
        str.replace(/\~\~[^\~]*\~{0,1}[^\~]*\~\~/gi, function (match, position) {
            strRes += str.substring(index, position)
            strRes += "<s>" + match.substr(2, match.length - 4) + "</s>"
            index = position + match.length
        })
        strRes += str.substring(index)

        return strRes
    }
    ,
    dealMark(str) {
        if (str == null) {
            return ""
        }
        var strRes = ""
        var index = 0
        str.replace(/\`[^\`]*\`/gi, function (match, position) {
            strRes += str.substring(index, position)
            strRes += "<span style='" + STYLE.MARK + "'>" + match.substr(1, match.length - 2) + "</span>"
            index = position + match.length
        })
        strRes += str.substring(index)

        return strRes
    }
    ,
    dealItalic(str) {
        if (str == null) {
            return ""
        }
        var strRes = ""
        var index = 0
        str.replace(/\*[^\*]*\*/gi, function (match, position) {
            strRes += str.substring(index, position)
            strRes += "<em>" + match.substr(1, match.length - 2) + "</em>"
            index = position + match.length
        })
        strRes += str.substring(index)

        return strRes
    }
    ,
    dealPhonetic(str) {
        if (str == null) {
            return ""
        }
        var strRes = ""
        var index = 0
        str.replace(/\{.*\}/gi, function (match, position) {
            strRes += str.substring(index, position)
            var res = match.substring(1,match.length-1)
            strRes += "<ruby>" + res.split("|")[0] +"<rt>" +
                res.split("|")[1]+
                "</rt>"
                "</ruby>"
            index = position + match.length
        })
        strRes += str.substring(index)

        return strRes
    }
    ,
    dealFormulaInline(str, katex) {
        if (str == null) {
            return ""
        }
        var strRes = ""
        var index = 0
        str.replace(/\$[^\$]*\$/gi, function (match, position) {
            strRes += str.substring(index, position)
            strRes += "<span>" + katex.renderToString(match.substr(1, match.length - 2)) + "</span>";
            index = position + match.length
        })
        strRes += str.substring(index)

        return strRes
    },
    dealKeep(str, type) {
        var res = ""
        var index = 0
        var that = this
        if (type == "keep") {
            str.replace(/\\./gi, function (match, pos) {
                res += str.substring(index, pos)
                var char = match.substring(1)
                res += that.encode(char)
                index = pos + match.length
            })
            res += str.substring(index)
            return res
        } else {
            str.replace(/\@\#\#\@\@\#\#\@.{1,6}\&\@\@\#\@\@\&/gi, function (match, pos) {
                res += str.substring(index, pos)
                var char = match.substr(prefix.length, match.length - prefix.length - endfix.length)
                res += that.decode(char)
                index = pos + match.length
            })
            res += str.substring(index)
            return res
        }
    },
    dealQuote(str) {


        return ele
    },

    isH1(item) {
        return item.indexOf("# ") === 0 || encodeURIComponent(item).indexOf("%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%20") === 0
    },
    isH2(item) {
        return item.indexOf("## ") === 0 || encodeURIComponent(item).indexOf("%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%20") === 0
    },
    isH3(item) {
        return item.indexOf("### ") === 0 || encodeURIComponent(item).indexOf("%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%20") === 0
    },
    isH4(item) {
        return item.indexOf("#### ") === 0 || encodeURIComponent(item).indexOf("%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%23%20") === 0
    },
    isH5(item) {
        return item.indexOf("##### ") === 0 || encodeURIComponent(item).indexOf("%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%23%23%20") === 0
    },
    isH6(item) {
        return item.indexOf("###### ") === 0 || encodeURIComponent(item).indexOf("%23%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%23%23%23%23%23%23%20") === 0
    },
    isCenterH1(item) {
        return item.indexOf("C# ") === 0 || encodeURIComponent(item).indexOf("C%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%20") === 0
    },
    isCenterH2(item) {
        return item.indexOf("C## ") === 0 || encodeURIComponent(item).indexOf("C%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%20") === 0
    },
    isCenterH3(item) {
        return item.indexOf("C### ") === 0 || encodeURIComponent(item).indexOf("C%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%20") === 0
    },
    isCenterH4(item) {
        return item.indexOf("C#### ") === 0 || encodeURIComponent(item).indexOf("C%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%23%20") === 0
    },
    isCenterH5(item) {
        return item.indexOf("C##### ") === 0 || encodeURIComponent(item).indexOf("C%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%23%23%20") === 0
    },
    isCenterH6(item) {
        return item.indexOf("C###### ") === 0 || encodeURIComponent(item).indexOf("C%23%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%23%23%23%C2%A0") === 0 || encodeURIComponent(item).indexOf("C%E2%80%8B%23%23%23%23%23%23%20") === 0
    },
    isH(item) {
        if (this.isH1(item)) {
            return [true, "H1"]
        } else if (this.isH2(item)) {
            return [true, "H2"]
        } else if (this.isH3(item)) {
            return [true, "H3"]
        } else if (this.isH4(item)) {
            return [true, "H4"]
        } else if (this.isH5(item)) {
            return [true, "H5"]
        } else if (this.isH6(item)) {
            return [true, "H6"]
        } else if (this.isCenterH1(item)) {
            return [true, "HC1"]
        } else if (this.isCenterH2(item)) {
            return [true, "HC2"]
        } else if (this.isCenterH3(item)) {
            return [true, "HC3"]
        } else if (this.isCenterH4(item)) {
            return [true, "HC4"]
        } else if (this.isCenterH5(item)) {
            return [true, "HC5"]
        } else if (this.isCenterH6(item)) {
            return [true, "HC6"]
        } else {
            return [false, ""]
        }
    },
    isQuote(item) {
        return this.isQuote1(item)
    },
    isQuote1(item) {
        return item.indexOf(">") === 0 || encodeURIComponent(item).indexOf("%3E%") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%3E") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%3E") === 0
    },
    isQuote2(item) {
        return item.indexOf(">>") === 0 || encodeURIComponent(item).indexOf("%3E%3E") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%3E%3E") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%3E%3E") === 0
    },
    isQuote3(item) {
        return item.indexOf(">>>") === 0 || encodeURIComponent(item).indexOf("%3E%3E%3E") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%3E%3E%3E") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%3E%3E%3E") === 0
    },
    isList(item) {
        return this.isList1(item) || this.isList2(item) || this.isList3(item)
    },
    isList1(item) {
        return item.indexOf("- ") === 0 || encodeURIComponent(item).indexOf("-C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B-%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B-%20") === 0;
    },
    isList2(item) {
        return item.indexOf("  - ") === 0 || encodeURIComponent(item).indexOf("%C2%A0%20-%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%C2%A0%20-%C2%A0") === 0 || encodeURIComponent(item).indexOf("%C2%A0%20-%20") === 0;
    },
    isList3(item) {
        return item.indexOf("    - ") === 0 || encodeURIComponent(item).indexOf("%C2%A0%20%C2%A0%20-%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%C2%A0%20%C2%A0%20-%C2%A0") === 0 || encodeURIComponent(item).indexOf("%C2%A0%20%C2%A0%20-%20") === 0;
    },
    isCode(item) {
        return item.indexOf("```") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%60%60%60") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%E2%80%8B%60%60%60") === 0 || encodeURIComponent(item).indexOf("%60%60%60") === 0
    },

    isTable(item) {
        return item.indexOf("|") === 0 || encodeURIComponent(item).indexOf("%7C%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%7C%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%7C") === 0 || encodeURIComponent(item).indexOf("%E2%80%8B%7C%20") === 0
    },
    isFormula(item) {
        return item.indexOf("$$") === 0 || encodeURIComponent(item).indexOf("%24%24") === 0 || (item.indexOf("$$") === item.length - 3&&item.indexOf("$$") >0) || (encodeURIComponent(item).indexOf("%24%24") === item.length - 3&&encodeURIComponent(item).indexOf("%24%24") >0) || encodeURIComponent(item).indexOf("%E2%80%8B%24%24") === 0
    },
    isLoopV(item){
        return item.indexOf("LV") === 0 || encodeURIComponent(item).indexOf("LV%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BL%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BL") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BL%20") === 0
    },
    isLoopH(item){
        return item.indexOf("LH") === 0 || encodeURIComponent(item).indexOf("LH%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BLH%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BLH") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BLH%20") === 0
    },
    isLoop(item){
        return item.indexOf("L") === 0 || encodeURIComponent(item).indexOf("L%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BL%C2%A0") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BL") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BL%20") === 0
    },
    isDivider(item) {
        if (item.length <= 2) return false
        return this.isRepeat("-", item)
    }
    ,
    isRepeat(char, item) {
        var isRepeat = true
        item.split("").forEach(value => {
            if (value != char) {
                isRepeat = false
                return
            }
        })
        return isRepeat
    },
    isRightP(item) {
        return item.indexOf("R ") === 0 || encodeURIComponent(item).indexOf("R%20") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BR%20") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BR%C2%A0") === 0
    },
    isCenterP(item) {
        return item.indexOf("C ") === 0 || encodeURIComponent(item).indexOf("C%20") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BC%20") === 0 || encodeURIComponent(item).indexOf("%E2%80%8BC%C2%A0") === 0
    },
    encode(char) {
        var replacer = ""
        for (let replaceKey in REPLACE) {
            if (char == REPLACE[replaceKey][0]) {
                replacer = prefix + encodeURIComponent(REPLACE[replaceKey][1]) + endfix
                return replacer
            }
        }
        if (replacer == '') {
            replacer = prefix + encodeURIComponent(char) + endfix
        }
        return replacer
    },
    decode(char) {
        var res = ''
        for (let replaceKey in REPLACE) {
            if (char == REPLACE[replaceKey][1]) {
                res = REPLACE[replaceKey][0]
                return res
            }
        }
        if (res == '') {
            res = decodeURIComponent(char)
        }
        return res
    },
    getFootNote() {
        if (footNoteList.length > 0) {
            let FOOTNOTE = footNoteList
            footNoteList = []
            return FOOTNOTE;
        }
        return []
    },
    setImage(str) {
        imageList.push(str)
    },
    getImageList() {
        return imageList
    },
    getImage(index) {
        if (imageList.length > index) {
            return imageList[index]
        }
        return "http://hyz.cool/public/R/img/logo.png"
    }

}
