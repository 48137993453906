<template>
    <div class="box">
        <input type="file" id="file" @change="selectFile" accept="image/png,image/jpg,image/jpeg,image/gif" multiple/>
        <div class="img-grid">
            <div class="img-item" v-for="item in imgList">
                <img :src="item.base64">
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                imgList: []
            }
        },
        methods: {
            selectFile() {
                //获取文件列表
                var fileInputList = document.getElementById("file").files
                var that = this
                //初始化数据
                that.imgList = []
                setImgData(fileInputList)

                //递归顺序写入数据
                function setImgData(input) {
                    var index = that.imgList.length
                    if (input.length > index) {
                        var name = fileInputList[index].name
                        blobToDataUrl(fileInputList[index], base64 => {
                            that.imgList.push({
                                name,
                                base64
                            })
                            if (input.length > index) {
                                //递归
                                setImgData(input, that.imgList)
                            }
                        })
                    }
                }

                //blob转为base64
                function blobToDataUrl(blob, output) {
                    let reader = new FileReader()
                    reader.onload = function (evt) {
                        let base64 = evt.target.result
                        output(base64)
                    }
                    reader.readAsDataURL(blob)
                }
            }
        }
    }
</script>

<style scoped>
    body {
        margin: 0;
        padding: 0;
        overflow: auto !important;
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box input {
        margin: 20px;
    }

    .box .img-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .box .img-grid .img-item {
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box img {
        width: 200px;
        height: 120px;
    }

    .box span {
        margin: 5px;
    }
</style>
