<template>
    <div class="editor-box">
        <div class="left-box" id="left-box">
            <div id="editor-view" class="editor-view">
                <textarea id="editor" class="editor" spellcheck="false" maxlength="250000" @focus="onFocus"
                          v-model="value" @input="onInput"/>
            </div>
            <div style="display: flex;margin-left: 18px;">
                <div style="margin: 0 15px">输入行数：{{inputLineNum}}</div>
                <div>输入字符数：{{inputNum}}</div>
            </div>
        </div>
        <div class="right-box" id="right-box">
            <div class="view-box">
                <div class="view" id="view">
                    <section id="view-section"/>
                </div>
                <div style=" display: flex; margin-left: 18px;">
                    <div style="margin: 0 15px">行数：{{textLineNum}}</div>
                    <div>字数：{{textNum}}</div>
                </div>
            </div>
            <div>
                <el-tooltip class="item" effect="dark" content="微信复制" placement="left">
                    <div id="copy-wechat" type="primary" @click="copy('copy-wechat','view-section')"
                         data-clipboard-target="#view-section" style="margin : 20px 5px 0 0;cursor: pointer;">
                        <img src="https://res.wx.qq.com/a/fed_upload/9300e7ac-cec5-4454-b75c-f92260dd5b47/logo-mp.ico"
                             height="40" width="40"/>
                    </div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="viewType=='PHONE'?'Web预览':'手机预览'" placement="left">
                    <div style="margin : 20px 5px 0 0;cursor: pointer;" @click="changeViewType">
                        <div v-if="viewType=='PHONE'">
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                                <path d="M512.3072 883.3024c-41.2672 0-74.8544-33.5872-74.8544-74.8544s33.5872-74.8544 74.8544-74.8544 74.8544 33.5872 74.8544 74.8544S553.5744 883.3024 512.3072 883.3024zM512.3072 770.4576c-20.992 0-37.9904 16.9984-37.9904 37.9904s16.9984 37.9904 37.9904 37.9904 37.9904-16.9984 37.9904-37.9904S533.1968 770.4576 512.3072 770.4576z"
                                      p-id="1256"></path>
                                <path d="M680.1408 991.9488c-3.1744 0-6.4512-0.8192-9.4208-2.56-41.2672-24.4736-108.032-24.3712-108.7488-24.3712 0 0-0.1024 0-0.1024 0L276.8896 965.0176c-54.3744 0-98.5088-44.2368-98.5088-98.5088L178.3808 140.3904c0-54.3744 44.2368-98.5088 98.5088-98.5088l470.7328 0c54.3744 0 98.5088 44.2368 98.5088 98.5088l0 726.1184c0 54.3744-44.2368 98.5088-98.5088 98.5088-10.1376 0-18.432-8.2944-18.432-18.432s8.2944-18.432 18.432-18.432c33.9968 0 61.6448-27.648 61.6448-61.6448L809.2672 140.3904c0-33.9968-27.648-61.6448-61.6448-61.6448L276.8896 78.7456c-33.9968 0-61.6448 27.648-61.6448 61.6448l0 726.1184c0 33.9968 27.648 61.6448 61.6448 61.6448l284.8768 0c4.1984 0 78.0288-0.1024 127.7952 29.4912 8.704 5.2224 11.6736 16.4864 6.4512 25.2928C692.5312 988.7744 686.4896 991.9488 680.1408 991.9488z"
                                      p-id="1257"></path>
                                <path d="M589.824 149.7088 434.7904 149.7088c-10.1376 0-18.432-8.2944-18.432-18.432s8.2944-18.432 18.432-18.432L589.824 112.8448c10.1376 0 18.432 8.2944 18.432 18.432S599.9616 149.7088 589.824 149.7088z"
                                      p-id="1258"></path>
                                <path d="M705.024 230.0928c9.728 0 17.7152 7.8848 17.7152 17.7152l0 376.9344c0 9.728-7.8848 17.7152-17.7152 17.7152L319.5904 642.4576c-9.728 0-17.7152-7.8848-17.7152-17.7152L301.8752 247.808c0-9.728 7.8848-17.7152 17.7152-17.7152L705.024 230.0928M705.024 193.2288 319.5904 193.2288c-30.1056 0-54.5792 24.4736-54.5792 54.5792l0 376.9344c0 30.1056 24.4736 54.5792 54.5792 54.5792l385.4336 0c30.1056 0 54.5792-24.4736 54.5792-54.5792L759.6032 247.808C759.6032 217.7024 735.1296 193.2288 705.024 193.2288L705.024 193.2288z"
                                      p-id="1259"></path>
                            </svg>
                        </div>
                        <div v-else>
                            <svg viewBox="0 0 1024 1024"
                                 xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                                <path d="M815.666626 168.5723 209.015919 168.5723c-44.669362 0-80.886215 36.20662-80.886215 80.886215l0 424.653653c0 44.667316 36.217876 80.887238 80.886215 80.887238l606.650707 0c44.667316 0 80.885192-36.219923 80.885192-80.887238L896.551818 249.459538C896.551818 204.77892 860.333942 168.5723 815.666626 168.5723zM512.359692 734.777853c-22.343891 0-40.453341-18.10945-40.453341-40.442084 0-22.334681 18.10945-40.442084 40.453341-40.442084 22.333658 0 40.443108 18.107403 40.443108 40.442084C552.803823 716.668403 534.694373 734.777853 512.359692 734.777853zM835.88818 633.671107 188.802552 633.671107 188.802552 229.236961l647.084605 0L835.887157 633.671107zM653.912615 795.442514 370.808816 795.442514c-16.755616 0-30.332842 13.58439-30.332842 30.333866 0 16.744359 13.577227 30.331819 30.332842 30.331819l283.102776 0c16.746406 0 30.3134-13.586436 30.3134-30.331819C684.226015 809.027927 670.659021 795.442514 653.912615 795.442514z"
                                      p-id="11231"></path>
                            </svg>
                        </div>
                    </div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="续加仪的博客" placement="left">
                    <a href="https://www.hyz.cool" style="margin : 20px 5px 0 0;cursor: pointer;">
                        <img src="https://files.hyz.cool/files/config/a3723d1857b94601751d31489978efd1.jpg" height="40"
                             width="40">
                    </a>
                </el-tooltip>
                <br/>
                <el-tooltip class="item" effect="dark" content="导入md文件" placement="left">
                    <el-button size="small" style="margin-top: 5px;width: 40px" @click="importMd">导入</el-button>
                </el-tooltip>
                <br/>
                <el-tooltip class="item" effect="dark" content="导出md文件" placement="left">
                    <el-button size="small" style="margin-top: 8px;width: 40px" @click="exportMd">导出</el-button>
                </el-tooltip>

                <input type="file" style="position: absolute;top: -100px" id="file-md" accept=".txt,.md"
                       @change="selectMdFile">
            </div>
        </div>

        <div id="hljs" style="display: none">
            <div>
                <highlightjs autodetect :code="code_value"/>
            </div>
        </div>
        <div id="content-menu" class="content-menu"
             style="display: none;">
            <ul style="padding: 0">
                <li v-for="(item,index) in [['上传图片','Alt+P']]" @click="dealContentMenu(index)"
                    style="list-style: none;">
                    <div>
                        <span>{{item[0]}}</span>
                        <span>{{item[1]}}</span>
                    </div>
                </li>
            </ul>
        </div>
        <input style="z-index: -9999;position: absolute;top:-1000px;" type="file" id="file" @change="selectFile"
               accept="image/png,image/jpg,image/jpeg,image/gif"/>
    </div>


</template>

<script>
    import ClipboardJS from "clipboard"
    import explain from "./explain";
    import 'highlight.js/styles/atom-one-dark.css';
    import hljsVuePlugin from "@highlightjs/vue-plugin";
    import creator from "./creator";
    import {md} from "./md";
    import "./katex/katex.css"

    import {h} from 'vue'
    import {ElNotification, ElTooltip, ElButton, ElMessageBox} from 'element-plus'

    import * as imageConversion from "image-conversion";

    export default {
        components: {
            highlightjs: hljsVuePlugin.component,
            ElTooltip,
            ElButton
        },
        data() {
            return {
                value: "",
                lineHeight: 25,
                code_value: "",
                isInput: true,
                inputNum: 0,
                inputLineNum: 0,
                textNum: 0,
                textLineNum: 0,
                flag: "",
                viewType: "PHONE",
                range: null,
                timeout: null,
                maxNum: 250000
            }
        },
        mounted() {
            // Latex().then(res=>{
            //     console.log(res)
            // }).catch(err=>{
            //     console.log(err)
            // })
            // http.requestPostWithoutLock('/.netlify/functions/latex2svg',
            //     {latex: "$$\\frac{1}{6}$$"}).then(res=>{
            //         console.log(res)
            // }).catch(err=>{
            //     console.log(err)
            // })
            document.getElementById('editor').addEventListener("mousedown", (e) => this.onmousedown(e))

            document.getElementById('editor').addEventListener("paste", (e) => this.onPaste(e))
            document.getElementById('editor').addEventListener("contextmenu", (e) => this.onContextMenu(e))
            document.getElementById('editor').addEventListener("keydown", (e) => this.onkeydown(e))
            var that = this
            document.getElementById("editor").onscroll = (e) => {
                e.preventDefault()
                if (that.flag == "") {
                    that.flag = "editor"
                    that.onScroll("view")
                } else if (that.flag == "editor") {
                    that.onScroll("view")
                    that.flag = ""
                } else if (that.flag == "view") {
                    that.flag = ""
                }
            }
            document.getElementById("view").onscroll = (e) => {
                e.preventDefault()

                if (that.flag == "") {
                    that.flag = "view"
                    that.onScroll("editor")
                } else if (that.flag == "view") {
                    that.onScroll("editor")
                    that.flag = ""
                } else if (that.flag == "editor") {
                    that.flag = ""
                }

            }
            // document.getElementById("editor-bar").onmousedown = (ev) => {
            //     that.onEditorBarMouseDown(ev)
            // }
            this.clearConsole()
            this.value = md

            this.onInput()
            // document.getElementById("editor").innerText = md
            // new Scroll({
            //     bar: 'editor-bar',
            //     show: 'editor'
            // })
        },
        methods: {
            onFocus(e) {
                console.log(e.value)
            },
            onInput() {
                if (this.timeout != null) {
                    clearTimeout(this.timeout)
                }
                // console.log(this.isType)
                // if (!this.isInput) {
                //     return
                // }
                let text = this.value
                this.inputNum = text.length
                this.inputLineNum = text.split("\n").length
                if (this.inputNum >= this.maxNum) {
                    ElNotification({
                        title: '字数超过',
                        message: h('i', {style: 'color: teal'}, '字数超过' + this.maxNum),
                    })
                    return;
                }

                this.timeout = setTimeout(() => {

                    var view = document.getElementById("view")
                    var sections = []
                    sections.push(view.querySelector("#view-section"))
                    if (text[0] == "​") {
                        text = text.substring(1)
                    }

                    sections.forEach(section => {
                        // section.innerHTML = "<p style='text-align: center;'>微信搜索关注公众号“续加仪“</p>"
                        section.innerHTML = ""
                    })

                    var textArr = explain.getTextArray(text)

                    textArr.forEach((item, index) => {
                        if (item != "" && item != null) {
                            creator.create(item, sections, explain, index === textArr.length - 1)
                        }
                    })
                    sections.forEach(section => {
                        this.textNum = section.innerText.length
                        this.textLineNum = section.innerText.split("\n\n").length

                    })
                    this.onScroll("view")

                }, 150)
            },
            onScroll(name) {
                var view = document.getElementById("view")
                var editor = document.getElementById("editor")
                if (name == "view") {
                    view.scrollTo(view.offsetLeft, (editor.scrollTop) * (view.scrollHeight - view.offsetHeight) / (1.0 * (editor.scrollHeight - editor.offsetHeight)))
                } else if (name == "editor") {
                    editor.scrollTo(editor.offsetLeft, (view.scrollTop) * (editor.scrollHeight - editor.offsetHeight) / (1.0 * (view.scrollHeight - view.offsetHeight)))
                }
            },
            onContextMenu(e) {
                this.range = window.getSelection().getRangeAt(0);
                e.preventDefault()
                var menu = document.getElementById("content-menu")
                console.log(e)
                menu.style.top = (e.clientY - 50) + "px"
                menu.style.left = (e.clientX + 20) + "px"
                menu.style.display = ""

            },
            onmousedown(e) {

                console.log(e)
                var menu = document.getElementById("content-menu")
                if (menu.offsetLeft > e.clientX ||
                    e.clientX > (menu.offsetWidth + menu.offsetLeft) ||
                    menu.offsetTop > e.clientY - 50 ||
                    e.clientY - 50 > (menu.offsetHeight + menu.offsetTop)) {
                    menu.style.display = "none"
                }
            },
            onkeydown(e) {
                console.log(e)
                document.getElementById("content-menu").style.display = "none"

                //上传图片Alt+P
                if (e.keyCode == 80 && e.altKey) {
                    this.range = window.getSelection().getRangeAt(0);
                    this.dealContentMenu(0)
                }
            },
            dealContentMenu(index) {
                switch (index) {
                    case 0: {
                        document.getElementById("file").click()
                    }
                }
            },
            changeViewType() {
                this.viewType = this.viewType == "PHONE" ? "WEB" : "PHONE"
                this.viewType == "PHONE" ? document.getElementById("view-section").style.width = "25vw" :
                    document.getElementById("view-section").style.width = "35vw"
            },
            insertText(obj, str) {
                if (document.selection) {
                    var sel = document.selection.createRange();
                    sel.text = str;
                } else if (typeof obj.selectionStart === 'number' && typeof obj.selectionEnd === 'number') {
                    var startPos = obj.selectionStart,
                        endPos = obj.selectionEnd,
                        cursorPos = startPos,
                        tmpStr = obj.value;
                    obj.value = tmpStr.substring(0, startPos) + str + tmpStr.substring(endPos, tmpStr.length);
                    cursorPos += str.length;
                    obj.selectionStart = obj.selectionEnd = cursorPos;
                } else {
                    obj.value += str;
                }
            },
            selectFile(e) {
                var file = document.getElementById("file")
                var UPLOAD_SIZE = 1024

                function beforeUpload(target) {
                    return new Promise(resolve => {
                        console.log(target)
                        console.log(target.size)
                        if (target.size / 1024 < UPLOAD_SIZE) {
                            resolve(target);
                        }
                        // 压缩到1024KB,这里的1024就是要压缩的大小,可自定义
                        imageConversion
                            .compressAccurately(target, UPLOAD_SIZE)
                            .then(res => {
                                resolve(res);
                            });
                    });
                }

                beforeUpload(file.files[0]).then(res => {
                    document.getElementById("content-menu").style.display = "none"
                    // var img = getFileUrl(res)
                    // var that = this
                    // blobToDataUrl(img, (base64) => {
                    blobToDataUrl(res, (base64) => {
                        explain.setImage(base64)

                        // var span = document.createElement("span")
                        // span.innerText =
                        // this.insertText(document.getElementById('editor'), "![" + img.name + "](P" + explain.getImageList().length + ")")
                        this.insertText(document.getElementById('editor'), "![" + file.files[0].name + "](P" + explain.getImageList().length + ")")
                        document.getElementById('editor').dispatchEvent(new Event('input', {bubbles: true}));
                        // that.range.insertNode(span)
                    })
                }).catch(err => {
                    console.log(err)
                })

                //获取input[file]图片的url Important
                function getFileUrl(file) {
                    console.log(file)
                    var url = ""
                    var agent = navigator.userAgent;
                    if (agent.indexOf("MSIE") >= 1) {
                        url = file.value;
                    } else if (agent.indexOf("Firefox") > 0) {
                        url = file.files.item(0);
                    } else if (agent.indexOf("Chrome") > 0) {
                        url = file.files.item(0);
                    }
                    return url;
                }

                function blobToDataUrl(blob, cb) {
                    let reader = new FileReader()
                    reader.onload = function (evt) {
                        let base64 = evt.target.result
                        cb(base64)
                    }
                    reader.readAsDataURL(blob)
                }
            },

            onEditorBarMouseDown(ev) {
                var scrollBar = document.getElementById("editor-bar")
                var editor = document.getElementById("editor")
                var editorView = document.getElementById("editor-view")

                let e = ev || event;
                e.preventDefault();
                e.stopPropagation();
                let to_t = e.clientY - scrollBar.offsetTop;
                scrollBar.style.background = 'maroon'
                document.onmousemove = (ev) => {
                    let e = ev || event;
                    let t = e.clientY - to_t;
                    if (t < 0) {
                        t = 0;
                    } else if (t > editorView.offsetHeight - scrollBar.offsetHeight) {
                        t = editorView.offsetHeight - scrollBar.offsetHeight;
                    }
                    if (t >= 20 && t <= editor.offsetHeight - 10) {
                        scrollBar.style.marginTop = t + 'px';
                        editor.scrollTop = ((t - 20) * editor.scrollHeight / editorView.offsetHeight)
                    }
                }
                document.onmouseup = () => {
                    scrollBar.style.background = '#9cd2e2'
                    document.onmousemove = null;
                }
                editor.onmouseup = (ev) => {
                    scrollBar.style.background = '#9cd2e2'
                    document.onmousemove = null;
                }
            },
            startCursor() {
                var cursor = document.getElementById("cursor").parentElement
                var i = 1
                cursor.style.visibility = ""
                if (this.intervalController != null) {
                    clearInterval(this.intervalController)
                }
                this.intervalController = setInterval(() => {
                    cursor.style.visibility = i % 2 == 0 ? "hidden" : ""
                    i++
                }, 500)
            },
            copy(id, targetId) {
                var copy = new ClipboardJS('#' + id, {
                    container: document.getElementById(targetId),
                });
                console.log(copy)
                ElNotification({
                    title: '复制成功',
                    message: h('i', {style: 'color: teal'}, '现在可以前往微信公众号粘贴啦'),
                })
            },
            importMd() {
                if (this.value) {
                    ElMessageBox.confirm(
                        '检测到未保存的内容，导入内容是否追加在已有文末？', '提示信息', {
                            distinguishCancelAndClose: true,
                            confirmButtonText: '追加文末',
                            cancelButtonText: '覆盖'
                        }).then(res => {
                        this.appendMd = true
                        document.getElementById("file-md").click();
                    }).catch(err => {
                        if(err==='cancel'){
                            this.appendMd = false
                            document.getElementById("file-md").click();
                        }
                    })
                } else {
                    this.appendMd = false
                    document.getElementById("file-md").click();
                }
            },
            selectMdFile(e) {
                var files = document.getElementById("file-md")
                var fileMd = files.files[0]
                let reader = new FileReader();
                var that = this
                reader.onload = function () {
                    that.value =( that.appendMd ? (that.value+ "\n") : "")  + this.result;
                    files.value=''
                    // document.getElementById('editor').dispatchEvent(new Event('input', {bubbles: true}));
                };
                reader.readAsText(fileMd);
            },
            exportMd() {

                function download_txt(filename, text) {
                    var pom = document.createElement('a');
                    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                    pom.setAttribute('download', filename);
                    if (document.createEvent) {
                        var event = document.createEvent('MouseEvents');
                        event.initEvent('click', true, true);
                        pom.dispatchEvent(event);
                    } else {
                        pom.click();
                    }
                }

                var date = new Date()
                download_txt(date.getFullYear() + '.' + (date.getMonth() + 1) + "." + date.getDate() + '.md', this.value);
            },

            clearConsole() {
                console.clear()
                console.log = () => {

                }
                console.error = () => {

                }
                console.warn = () => {

                }
                // setInterval(() => {
                //         console.clear()
                //     }, 1000
                // )
            },
            onPaste(e) {
                // if (this.textNum > this.maxNum) {
                //     this.$message("字数过多")
                //     //阻止默认复制
                //     e.preventDefault()
                //     return
                // }
                // e = (e.originalEvent || e)
                // var text = e.clipboardData.getData('text/plain')
                // if (this.textNum + text.length > this.maxNum) {
                //     this.$message("字数过多")
                //     e.preventDefault()
                //     text = text.substring(this.maxNum - this.textNum)
                //     document.execCommand('insertText', false, text);
                // }
                // setTimeout(() => {
                //     this.onInput()
                // }, 0)

            },

        }
    }
</script>

<style scoped>


    .editor-box {
        display: flex;
        justify-content: space-between;
        max-height: 100vh;
        overflow: hidden;
    }

    .right-box {
        display: flex;
        flex-direction: row;
    }

    .left-box {
        display: flex;
        flex-direction: column;
        width: 65vw;
    }


    .editor-view {
        display: flex;
        flex-direction: row;
        height: 100vh;


    }

    .editor {
        border-radius: 20px;
        cursor: text;
        background: #eeefee;
        color: #57643d;
        width: 100%;
        margin: 15px;
        padding: 20px;
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
        outline: none;
        border: none;
        word-break: break-all;
        line-height: inherit;
        font-size: 20px;
        overflow-y: scroll;
        font-family: Optima-Regular, Optima, PingFangSC-light, PingFangTC-light, "PingFang SC", Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    }


    .editor span {
        color: black;
        font-family: Optima-Regular, Optima, PingFangSC-light, PingFangTC-light, "PingFang SC", Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    }

    .view-box {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .view {
        border-radius: 20px;
        margin: 15px;
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        padding: 25px 20px;

    }

    .view section {
        width: 25vw;
    }

    #view-section {
        outline: none;
    }

    .content-menu {
        position: absolute;
        background: white;
        box-shadow: 0 0 8px 0px #27262638;
        width: 150px;
    }

    .content-menu div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px;
    }

    .content-menu div:active {
        background: #e6e6e6;
    }


</style>
